import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../config/theme';

import { AppText } from '../styles/universalStyles';


const Wrapper = styled.div`
  border-radius: 15px;
  border: 1px solid ${colors.PRIMARY};
  padding: 3px 10px;
  margin-right: 10px;
  border-color: ${(props) => props.active ? colors.PRIMARY : colors.LIGHTER_GREY};
  cursor: pointer;
`;

const TagText = styled(AppText)`
  color: ${(props) => (props.active ? colors.PRIMARY : colors.LIGHT_GREY)};
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'lowercase')};
`;

const Tag = ({ tagText, tagAction, ...props }) => {
  const [active, setActive] = useState(false);
  const handleActive = () => {
    setActive(!active);
  };

  return (
    <Wrapper
      active={props.active || active}
      onClick={tagAction || handleActive}>
      <TagText
        capitalize={props.capitalize}
        active={props.active || active}
        small
        semiBold>
        {tagText}
      </TagText>
    </Wrapper>
  );
};

export { Tag };
