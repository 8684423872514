import { mobile_RE } from "./regex";

export const validatePhone = (number) => {
  let validMobile = mobile_RE.test(number);
  let errorMessage = ''

  // check number validity
  if (!validMobile) {
    errorMessage = 'Please enter a valid 10 digit phone number'
  }

  return errorMessage

};