import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../config/theme'
import { AppBtn, AppText, Flex } from '../styles/universalStyles'

const Wrapper = styled.div`
`

const BtnWrapper = styled(Flex)`
  border: 0.5px solid ${colors.PRIMARY};
  background-color: ${(props) => (props.fill ? colors.PRIMARY : colors.WHITE)};
  cursor: pointer;
  /* gap: 5px; */
  padding: 5px 8px;
  justify-content: space-between;
  width: 110px;
  border-radius: 0px;
`

const AddBtn = styled(AppBtn)`
  width: 110px;
  padding: 6px 12px;
  border-radius: 0px;
`;



const BtnAction = styled.div`
  font-size: 18px;
  background-color: ${(props) => (props.fill ? colors.PRIMARY : colors.WHITE)};
  padding: 0px 10px;
`

const DisplayCount = styled.div`
  font-size: 14px;
`

export const MenuItemAddBtn = ({ action, initialAction, itemCount, ...props }) => {

  const handleItemUpdate = (number) => {
    if (itemCount === 0) {
      initialAction(number)
    }
    else { action(number) }
  }

  return (
    <Wrapper>
      {
        itemCount > 0 ?
          (
            <BtnWrapper>
              <BtnAction onClick={() => handleItemUpdate(-1)}>{" - "}</BtnAction>
              <DisplayCount>{itemCount}</DisplayCount>
              <BtnAction onClick={() => handleItemUpdate(+1)}>{" + "}</BtnAction>
            </BtnWrapper>
          )
          :
          (
            <AddBtn
              disabled={itemCount ? true : false}
              onClick={() => handleItemUpdate(+1)}
            >
              <AppText orange={!itemCount} semiBold small>
                {itemCount || 'ADD +'}
              </AppText>
            </AddBtn>
          )
      }
    </Wrapper>

  )
}