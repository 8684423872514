// this generates the paymentID
export const generatePID = async () => {
  let randomStr;
  if (!Date.now) {
    randomStr = new Date().getTime().toString();
  } else {
    randomStr = Date.now().toString();
  }
  const idArray = randomStr.match(/.{1,4}/g);
  const pid = idArray.join('-');
  return `${pid}LG`;
};
