import React from 'react'

export const Privacy = () => {
  return (
    <div className="container">
      <h1>Privacy policy</h1>
      <br />
      <h5>Privacy policy Personal Information We Collect:</h5>
      We collects several types of information regarding our Guest User and
      Registered Users. We collect personally identifiable information from our
      users. The personal information collected by us mainly consists of Contact
      Details (i.e. Email Address, Contact Number), Personal Details (i.e. User
      Name), Billing Details (i.e. Physical Billing address, Payment Method,
      Transaction Details), User Preferences (i.e. Preferences of order method,
      Time-zone, Language), User Comments (i.e. Feedback, Complain). We collect
      information so that you can place order, request information and support
      and make product suggestions. Our website and mobile application use forms
      to collects information. We receive and store your information you enter
      on our website or mobile applications, or any other way like email,
      telephone or other communications with our customer service team. If you
      contact us for support, we will keep an internal records for that also.
      <h5>How We Use the Information We Collect:</h5>
      Information will be used to process your order and to manage your account.
      We will use your information to contact you in response to your suggestion
      or query, regarding the functionality changes to our products, any special
      offers which will be suitable to you and changes in Privacy Policy. We use
      emails to confirm orders placed by you or to send information requested by
      you. We are also providing email links and phone number to contact us
      directly. We are eager to reply your message. The information which you
      have sent to us may be reviewed, discarded or used. These information may
      be used to improve our Website, Application, Product and Services. To
      process your order we may send your information to credit reference and
      fraud prevention agencies.
      <h5>Cookies:</h5>
      A cookie is a piece of data stored on a site visitor’s hard drive to help
      us improve your access to our site and identify repeat visitors to our
      site. Cookies can also help customize the site for visitors. Personal
      information cannot be collected via cookies and other tracking technology,
      however, if you previously provided personally identifiable information,
      cookies may be tied to such information. We are not storing any cookies to
      browser by code, its browsers feature to store cookies like clicking on
      save password.
      <h5>Security:</h5>
      We believe the security of your information is a serious issue and we are
      committed to protecting the information we receive from you. We take
      precautions to protect your information. We use security measures to
      protect against the loss, misuse or alteration of the information under
      our control. When you submit sensitive information via website or
      application, your information is protected both online and offline.
      Whenever you enter sensitive information on our forms, the information is
      encrypted using MD5 and transmitted to us in a secure way. While we use
      encryption to protect your sensitive information transmitted online, we
      also protect your information offline. The computers/servers in which we
      store personally identifiable information are kept in a secure
      environment. We will retain personal information only as long as our
      business needs require. We will then destroy or render unreadable any such
      information upon disposal. However, we do not guarantee that unauthorized
      access will never occur. Users who have registered to the site agree to
      keep their password in strict confidence and not disclose such password to
      any third party.
      <h5>Sharing Information with Third Party:</h5>
      We may share information about you to third parties to provide various
      services on our behalf to fulfil your request (i.e. to ship your order, to
      process credit card payments, serving advertisements, conducting surveys,
      Customer relationship managements, performing analysis of our service). We
      will only share information about you that is necessary for third party to
      provide the requested service. These third parties are prohibited from
      retaining, sharing, storing or using your personally identifiable
      information for any other purpose. This policy does not apply to
      information that you provide to any third party such as social network
      that you use in connection with our services.
      <h5>Changes in Privacy Policy:</h5>
      We reserve all rights to modify this Privacy Policy at any time according
      to our need. Our Privacy Policy may change from time to time and all
      updates will be posted on this App. Our Privacy Policy may be changed
      according to changes in law, our features and services, advances in
      technology. Please visit our Privacy Policy page regularly for update in
      Privacy Policy. Please review our Privacy Policy carefully. Your continued
      use of the Services following the posting of changes to Privacy Policy
      will constitute your consent and acceptance of those changes.
      <h5>Disclaimer:</h5>
      The contents published on this Website(Newsletter and Tenacious Techies
      Pvt. Ltd. social media channels)have been carefully researched, but we
      assume no liability for the correctness and topicality of those.
      <h5>Copyright and image Rights:</h5>
      All written content and those containing images are subject to Nigerian
      copyright law, so that exploitation of this requires the prior written
      consent of the author and companies we are partnering with.
      <h5>No legal:</h5>
      Advice Data protection advice does not constitute legal advice within the
      meaning of the Legal Services Act. The protection of your personal data is
      important to us. In our privacy policy we provide information about the
      purposes we process your data; on what legal basis this is done and what
      rights and claims you have in this context. Information Provided as
      Mandated by Article 13 GDPR If this is your first interaction with us,
      Art. 12, 13 GDPR mandates that we make available to you the following
      mandatory data protection related information: If you are contacting us
      via website or e-mail, we will process your personal data only if we have
      a legitimate interest in the processing of this data (Art. 6(1)(f) GDPR),
      if you have consented to the processing of your data (Art. 6(1)(a) GDPR),
      if the processing of the data is required for the development,
      establishment, content or modification of a legal relationship between you
      and our company (Art. 6(1)(b) GDPR) or if any other legal provision
      permits the processing of this data. Your personal data will remain in our
      possession until you ask us to delete the data, or you revoke your consent
      to store the data or if the purpose the data stored is required for no
      longer exists (e.g., once your request has been conclusively processed).
      This shall be without prejudice to any compelling statutory provisions –
      in particular tax and commercial law-based retention periods. You have the
      right to at any time receive free information concerning the origins,
      recipients and purpose of your data archived by us. You also have a right
      to object, to data portability and a right to log a complaint with the
      competent supervisory agency.<br />
      Moreover, you can demand the correction, eradication and, under certain
      circumstances, the restriction of the processing of your personal data.
      For more details, please consult our Data Privacy Policy in this app.
      <h5>Contact Details</h5>
      If you have any Query, Feedback or Concern about this Privacy Policy, you
      can write to us or reach out to us using following contact details.
      <h5>Email: lucianasgrill@gmail.com</h5>
      <h5>Phone: +2348095778857</h5>
      <h5>Whatsapp: +2348095778857</h5>
    </div>
  )
}
