import { PAYSTACK_KEYS } from "../data/api";
import { VERIFY_PAYMENT_API } from "./endPoints";

export const getPaymentConfig = (email, amount) => {
  return {
    email: email || "lucianasgrill@gmail.com",
    amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEYS.PUBLIC_KEY,
  }
}



export const verifyPayment = async (reference) => {
  try {
    const res = await fetch(VERIFY_PAYMENT_API + reference);
    const data = await res.json();

    return data.data;

  } catch (err) {
    // console.error("An error occured====>", { err });
  }
}