import React from 'react'
import styled from 'styled-components'
import { Space } from '../../styles/universalStyles'
import { FormGroupComp } from '../../components/FormGroup'
import { AppButton } from '../../components/AppButtons'


const Wrapper = styled.div`
  margin: 10em auto;
  background-color: rgba(255,255,255,0.5);
  width: 50%;
  padding: 7em 1em;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.5);
`

const Title = styled.h3`
  font-size: 24px;
  text-align: center;
`

const Form = styled.form`
  width: 45%;
  margin: auto;
`

const NewPassword = () => {
  return (
    <Wrapper>
      <Title>Welcome Admin: Login to continue</Title>
      {/* 
        The user comes to this page through a link in his email and from there we can get his user cred

        TODO: this will use route params to get user's email. Display the email as a label text
      */}

      <Form>
        <FormGroupComp
          labelName="New Password"
          placeholder="new password"
          inputType="password"
          required
        />

        <Space size="30px" />

        <FormGroupComp
          labelName="Confirm Password"
          placeholder="confirm password"
          inputType="password"
          required
        />

        <AppButton>
          Submit
        </AppButton>
      </Form>

    </Wrapper>
  )
}


export { NewPassword }