import { firebaseGetCollection } from '../../util/apiRequests';
import { SAVE_MENU } from '../config/type';

export const saveMenu = (menuItems) => (dispatch) => {
  try {
    dispatch({
      type: SAVE_MENU,
      payload: menuItems,
    });
  } catch (error) {
    // console.log('An error occured while saving menu to store', error);
  }
};

export const getMenu = () => async (dispatch) => {
  const menuItems = await firebaseGetCollection("menu")
  try {
    dispatch({
      type: SAVE_MENU,
      payload: menuItems,
    });
  } catch (error) {
    // console.log('An error occured while saving menu to store', error);
  }
};
