import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { EnvironmentOutlined, CompassOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import Geocoder from 'react-native-geocoding';


import { AppInput } from '../AppInput';
import { CardComp } from '../CardComp';
import { AppText, Flex, Space } from '../../styles/universalStyles';
import { initSearchService } from '../../util/places';
import { colors } from '../../config/theme';
import { capitalizeFormatter } from '../../services/util/formatter';
import { AppDrawer } from '../Drawers';
import { EditLocation } from '../EditLocation';
import { LoadingComp } from '../LoadingComp';
import { errorCallBack, geolocationOptions, successCallBack } from '../../services/util/geolocation';
import { GooglemapComp } from '../GooglemapComp';
import { DisplayAddress } from '../DisplayAddress';
import { MAP_KEY } from '../../services/data/api';
import { useMediaQuery } from '../../services/customHooks/useMediaQuery';
import { device } from '../../util/devices';



const Title = styled(AppText)`
  font-weight: 500;
  margin: 0 10px;
  font-size: 18px;
`

const Subtitle = styled.p`
  color: rgba(0,0,0,0.8);

`

const LocationCompBase = ({ placement, deliveryLocation, closeLocationDrawer, isMobile, ...props }) => {
  const [searchInput, setSearchInput] = useState('')
  const [predictions, setPredictions] = useState([]);
  const [searchError, setSearchError] = useState(false)
  const [showEditDawer, setShowEditDawer] = useState(false);
  const [selected, setSelected] = useState('');

  const [geolocationExist, setGeolocationExist] = useState(false)
  const [locationLoading, setLocationLoading] = useState(false)
  const [coords, setCoords] = useState({})
  const [errorCoords, setErrorCoords] = useState(false)
  const [latlng, setLatlng] = useState({})
  const [userPosition, setUserPosition] = useState({})
  const [mapAddress, setMapAddress] = useState('')


  const deliveryLocationExist = Object.entries(deliveryLocation).length > 0


  const handleSearch = async (event) => {

    const text = event.target.value
    setSearchInput(event.target.value)

    try {
      await initSearchService(text, handlePredictions)
    } catch (error) {
      // console.log('An error occured while searching for location', error);
    }
  };

  const handlePredictions = (predictions, status) => {
    if (status === 'OK') {
      setPredictions(predictions)
      setSearchError(false)
    } else {
      setSearchError(true)
      // console.log("didn't find result")
    }

  }

  const handleSelection = async (selection) => {
    // setSelected(selection.description);
    setSelected(selection.description ? selection.description : selection);
    setLatlng({})
    setShowEditDawer(true);
  };

  const closeEditDrawer = () => {
    setShowEditDawer(false)
    setSearchInput("")
    closeLocationDrawer()
  }


  const handleGeolocation = async (address) => {
    setLocationLoading(true)

    if (!address) {

      await getUserGeolocationPermission()
    } else {
      const coordinates = await Geocoder.from(address)

      setLatlng(coordinates.results[0].geometry.location)


    }
    setLocationLoading(false)
    setSearchInput('')


  }

  // get user's permission to use current location
  const getUserGeolocationPermission = async () => {


    navigator.geolocation.getCurrentPosition(
      position => {
        setLatlng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })

        setUserPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })

        setErrorCoords(false)
        // setLocationLoading(false)

      },
      error => {
        setErrorCoords(true)
        // setLocationLoading(false)
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    )
  }

  const handleMapAddress = (address) => {
    setMapAddress(address)
  }


  useEffect(() => {
    // check if navigator exists
    if (navigator.geolocation) {
      setGeolocationExist(true)
      Geocoder.init(MAP_KEY, {
        language: 'en',
      });
    }

  }, [])



  return (
    <>
      <AppInput
        style={{ padding: "10px" }}
        placeholder="Search address"
        allowClear
        onChange={handleSearch}
        value={searchInput}
      />

      <Space size="2em" />

      {searchInput ?
        <>
          {
            searchError ?
              <SearchErrorComp />
              :
              <>
                {predictions.map((prediction, index) => {

                  return (
                    <div onClick={() => handleSelection(prediction)}>
                      <SearchResult
                        text={prediction.structured_formatting.main_text}
                        subText={prediction.structured_formatting.secondary_text}
                      />
                    </div>
                  )
                })}
                <Space size="2em" />
                <div
                  onClick={() => handleGeolocation(predictions[0].description)}
                >
                  <SearchResult
                    text="Use Map to locate this place"
                    subText={"Using GPS"}
                    gps
                  />
                </div>
                <Space size="2em" />

              </>
          }
        </>
        :
        <>
          {geolocationExist && (
            <>

              <Space size="3em" />

              {
                Object.entries(latlng).length > 0 ? (
                  <>
                    <CardComp hover>
                      <GooglemapComp
                        action={handleMapAddress}
                        position={latlng}
                        getUserLocation={getUserGeolocationPermission}
                        userPosition={userPosition}
                        isMobile={isMobile}
                      />
                    </CardComp>
                    <Space />
                    {!!mapAddress && (
                      <div onClick={() => handleSelection(mapAddress)}>
                        <SearchResult
                          text={mapAddress.split(",")[0]}
                          subText={mapAddress}
                          selectBtn

                        />
                        <Space size="2em" />
                      </div>
                    )}

                  </>
                )
                  :
                  <>
                    <CardComp hover>
                      <div onClick={() => handleGeolocation(null)}>
                        <Flex>
                          <EnvironmentOutlined style={{ fontSize: "20px" }} />
                          <Title level={2}>Use my current location in maps</Title>
                        </Flex>
                        <AppText style={{ marginLeft: "2.5em" }} small color={colors.TEAL}>Hot Fresh Boli delivered to your doorstep</AppText>

                      </div>

                    </CardComp>
                    <Space size="2em" />
                  </>
              }
            </>
          )}

        </>
      }

      {
        locationLoading && (
          <>
            <Space />
            <LoadingComp text="Fetching your current location ..." />
          </>
        )
      }



      {
        errorCoords &&
        <>
          <Space />
          <CardComp hover>
            <AppText color={colors.DANGER}>
              An error occured while fetching your location. Try again later or use the search box above
            </AppText>
          </CardComp>
        </>
      }


      {/* Delivery Location exists */}
      {deliveryLocationExist && (
        <DisplayAddress
          data={deliveryLocation}
          action={() => setShowEditDawer(true)}
        />
      )}
      {/* Edit Location Drawer */}
      <AppDrawer
        headerStyle={{ textAlign: 'left', }}
        toggleDrawer={closeEditDrawer}
        open={showEditDawer}
        placement={placement || "left"}
        width={'450px'}
        title={`Please Edit The Address Details`}
        bodyStyle={bodyStyle}
        isMobile={isMobile}
      >
        <EditLocation
          isMobile={isMobile}
          closeDrawer={closeEditDrawer}
          data={selected} />
      </AppDrawer>




    </>
  )
}


const SearchResultWrap = styled(Flex)`
  padding: 10px;
  gap: 15px;
  border-bottom: 1px dotted ${colors.LIGHTEST_GREY};

  &:hover{
    color: ${colors.LIGHT_PRIMARY};
    background-color: ${colors.LIGHTER_PRIMARY};
    cursor: pointer;
  }
`


const LocationDescription = styled.div`
`

const SearchResult = ({ text, subText, gps, selectBtn, ...props }) => {
  return (
    <SearchResultWrap>
      {gps ?
        <CompassOutlined style={{ fontSize: "20px" }} />
        :
        <EnvironmentOutlined style={{ fontSize: "20px" }} />
      }
      <LocationDescription>
        <AppText bold>{capitalizeFormatter(text)}</AppText>
        <Space size="1px" />
        <AppText fontSize="13px" color={colors.LIGHT_GREY}>{capitalizeFormatter(subText)}</AppText>
        <Space size="1px" />
        {selectBtn && (<AppText fontSize="13px" color={colors.LIGHT_PRIMARY}>Select this Location</AppText>)}

      </LocationDescription>
    </SearchResultWrap>
  )
}

const SearchErrorComp = () => (
  <AppText fontSize="16px" color={colors.DANGER}>No Results Found for this Search</AppText>
)


const bodyStyle = {
  width: '100%',
  padding: 0,
}


// const mapDispatchToProps = (dispatch) => {
//   return {
//     addOrderLocation: (data) => dispatch(addOrderLocation(data)),
//   };
// };

const mapStateToProps = state => {
  return {
    deliveryLocation: state.location.orderLocation || {},
    userData: state.user,
  }
}

export const LocationComp = connect(
  mapStateToProps,
  null,
)(LocationCompBase);



