import {
  ADD_TO_CART,
  UPDATE_CART,
  DELETE_ITEM,
  ADD_EXTRA_ITEM,
  DELETE_EXTRA_ITEM,
  RESET_CART,
  ADD_CUSTOMIZATION,
} from '../config/type';

export const addToCart = (item) => (dispatch) => {
  try {
    dispatch({
      type: ADD_TO_CART,
      payload: item,
    });
  } catch (error) {
    // console.log('something went wrong while adding to cart', error);
  }
};

export const updateCart = (item, ops) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CART,
      payload: { item: item, ops },
    });
  } catch (error) { }
};

export const addCustomization = (item) => (dispatch) => {
  try {
    dispatch({
      type: ADD_CUSTOMIZATION,
      payload: item,
    });
  } catch (error) { }
};

export const deleteCartItem = (item) => (dispatch) => {
  try {
    dispatch({
      type: DELETE_ITEM,
      payload: item,
    });
  } catch (error) { }
};

export const addExtra = (item) => (dispatch) => {
  try {
    dispatch({
      type: ADD_EXTRA_ITEM,
      payload: item,
    });
  } catch (error) { }
};
export const resetCart = () => (dispatch) => {
  try {
    dispatch({
      type: RESET_CART,
    });
  } catch (error) { }
};
export const deleteExtra = (item) => (dispatch) => {
  try {
    dispatch({
      type: DELETE_EXTRA_ITEM,
      payload: item,
    });
  } catch (error) { }
};
