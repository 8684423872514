export const bankDetails = [
  {
    name: 'Bank Name',
    value: 'Guaranty Trust Bank Plc',
  },
  {
    name: 'Account Name',
    value: 'Lucianas Grill',
  },
  {
    name: 'Account Number',
    value: '0632371666',
  },
];
