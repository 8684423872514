import { LoadingOutlined } from "@ant-design/icons";

import { colors } from "../config/theme"
import { AppParagraph, Flex, Space } from "../styles/universalStyles"
import { AppButton } from "./AppButtons"
import { AppConfirmation } from "./AppConfirmation"
import { AppInput } from "./AppInput"

export const DisplayPhoneComp = ({
  errorMessage,
  number,
  handleOnPhoneNumberChange,
  handlePhoneSignIn,
  enableBtn,
  phoneLoading,
  ...props }) => {
  return (
    <div>
      <Flex>
        <AppInput width="30%" disabled={true} value="+234" />
        <Space size="5px" />
        <AppInput
          maxLength="10"
          value={number}
          onChange={handleOnPhoneNumberChange}
          placeholder="Enter Phone number" />
      </Flex>
      <AppParagraph color={colors.DANGER}>{errorMessage}</AppParagraph>

      <div id="signInDiv"></div>
      <AppConfirmation
        title="Phone Number Verification"
        description={`A One Time Password (OTP) will be sent to this number: ${number}`}
        confirm={handlePhoneSignIn}
      >
        {/* <> */}
        <AppButton
          // action={handlePhoneSignIn}
          disabled={enableBtn}
          showIcon={phoneLoading}
          icon={<LoadingOutlined style={{ color: colors.WHITE, fontSize: "15px", fontWeight: 600 }} />}
          // text={phoneLoading ? "Sending OTP" : "Get OTP"}
          btnText={phoneLoading ? "Sending OTP ..." : "Get OTP"}
        // btnText={"Get OTP"}
        />
        {/* </> */}
      </AppConfirmation>
    </div>
  )
}