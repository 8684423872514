import React from 'react'
import { Empty } from 'antd'
import styled from 'styled-components'
import { colors } from '../config/theme'
import { ShoppingCartOutlined } from '@ant-design/icons';
import { TEXT_CONSTANTS } from '../services/constants/textconstant';



const EmptyCartWrapper = styled.div`
`

const EmptyTitle = styled.h5`
  font-weight: 400;
  font-size: 25px;
  color: ${colors.LIGHT_GREY};
  margin-bottom: 1em;
  text-align: center;
`

const EmptyDesc = styled.p`
  color: ${colors.LIGHT_GREY};
`

const ImageWrap = styled.div`
  background-color: ${colors.LIGHTER_PRIMARY};
  width: 50%;
  margin: auto;
  padding: 10px 0;
  border-radius: 30%;
  margin-bottom: 10px;
`


export const EmptyCart = () => (
  <EmptyCartWrapper>

    <EmptyTitle>
      Empty Cart!!!
    </EmptyTitle>

    <Empty
      image={
        <ImageWrap>
          <ShoppingCartOutlined style={{ fontSize: "6em", color: colors.LIGHTER_GREY }} />
        </ImageWrap>
      }
      description={
        <EmptyDesc>
          {/* We are always grilling something delicious! <br /> */}
          {TEXT_CONSTANTS.CART.EMPTY_DESC}
        </EmptyDesc>
      }
    />
  </EmptyCartWrapper>
)

