import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import { Navbar } from "../../components/NavbarComp/Navbar";
import { connect } from 'react-redux';
import { getMenu, saveMenu } from "../../services/redux/actions/menu";
import { firebaseGetCollection } from "../../services/util/apiRequests";
import { getUserData } from "../../services/util/user";
import styled from "styled-components";
import { themeConstants } from "../../config/theme";


const { Header, Content, Footer } = Layout;

const HeaderWrap = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100vw;
  background: inherit;
  box-shadow: ${themeConstants.BOX_SHADOW1};
  /* height: 75px; */
    
`

const PageHeader = () => (
  <HeaderWrap >
    <Navbar />
  </HeaderWrap>
)

const PageFooter = () => (
  <Footer>
    {/* <FooterComp /> */}
  </Footer>
)


export const PageLayoutBase = (props) => {
  useEffect(() => {
    getMenu()
    getUser()
  }, [])

  const getMenu = async () => {

    props.getMenu()

  }

  const getUser = async () => await props.getUser();

  return (
    <Layout>
      <PageHeader />

      <Content>
        <Outlet />
      </Content>

      <PageFooter />
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    menu: state.menu,
    status: state.app.orderStatus,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenu: () => dispatch(getMenu()),
    getUser: () => dispatch(getUserData())
  };
};

export const PageLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageLayoutBase);