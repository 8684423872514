import React, { useState } from 'react'
import styled from 'styled-components'
import { AppText, Space } from '../../styles/universalStyles'
import { FormGroupComp } from '../../components/FormGroup'
import { AppButton } from '../../components/AppButtons'
import { createUserWithEmail, saveUserData } from '../../services/util/user'
import { capitalizeFormatter } from '../../services/util/formatter'
import { colors } from '../../config/theme'
import { firebasePostDoc } from '../../services/util/apiRequests'


const Wrapper = styled.div`
  margin: 10em auto;
  background-color: rgba(255,255,255,0.5);
  width: 50%;
  padding: 7em 1em;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.5);
`

const Title = styled.h3`
  font-size: 24px;
  text-align: center;
`

const FormWrapper = styled.form`
  width: 45%;
  margin: auto;
`

const CreateSuperAdmin = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleCreateAdmin = async (event) => {
    event.preventDefault()
    setLoading(true)

    const formData = new FormData(event.target)

    const initialData = {
      email: formData.get("email"),
      password: formData.get("password"),
      displayName: formData.get("fullname")
    }

    const { error, message, user } = await createUserWithEmail(initialData);

    if (error) {
      setErrorMessage(capitalizeFormatter(message))
      setLoading(false)
      return

    } else {

      // Saves the user data to users collection
      const userData = {
        ...user,
        superAdmin: true,
      }

      await saveUserData(userData)

      // saves admin to admin collection
      await firebasePostDoc(
        'admins',
        user.uid,
        {
          displayName: userData.displayName,
          email: userData.email,
          superAdmin: userData.superAdmin,
          uid: userData.uid

        })
    }

    event.target.reset();
    setLoading(false)
  }

  return (
    <Wrapper>
      <Title>Welcome Admin: Login to continue</Title>

      <FormWrapper id="loginForm" onSubmit={handleCreateAdmin}>

        <div style={{ textAlign: "center" }}>
          <AppText color={colors.DANGER}>{errorMessage}</AppText>
          <Space size="2em" />
        </div>

        <FormGroupComp
          labelName="Full Name"
          placeholder="John Doe"
          inputType="fullname"
          id="fullname"
          name="fullname"
          required
        />

        <Space size="20px" />

        <FormGroupComp
          labelName="Email"
          placeholder="email"
          inputType="email"
          id="email"
          name="email"
          required
        />

        <Space size="20px" />

        <FormGroupComp
          labelName="Password"
          placeholder="password"
          inputType="password"
          id="password"
          name="password"
          required
        />
        <AppButton>
          {loading ? "Creating..." : "Create Admin"}
        </AppButton>

      </FormWrapper>







    </Wrapper>
  )
}


export { CreateSuperAdmin }