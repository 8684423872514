import React, { useState } from 'react'
import styled from 'styled-components'
import { Affix, Drawer } from 'antd';
import { AiOutlineCaretRight } from "react-icons/ai";
import { connect } from 'react-redux';

import { colors } from '../../config/theme'
import { Flex, Space, SpaceBetween } from '../../styles/universalStyles'
import { EmptyCart } from '../EmptyCart';
import { CartItem } from '../CartItem';
import { CheckoutBtnComp } from '../CheckoutBtn';
import { appLoader, placeOrder } from '../../services/redux/actions/app';
import { addCustomization, resetCart } from '../../services/redux/actions/cart';
import { addOrderLocation } from '../../services/redux/actions/location';
import { CheckoutComp } from '../CheckoutComp';
import { AppDrawer } from '../Drawers';


const Wrapper = styled.div`
  padding: 0;
  position: relative;
  margin-bottom: 4em;
  padding-bottom: 5em;
`
const HeaderArea = styled.div`
`

const SideTitle = styled.h1`
  margin-bottom: 0px;
  padding: 0;
  font-size: 2em;
  letter-spacing: 1px;
  font-weight: 800;
  font-family: Mulish;
`
const SideSubtitle = styled.p`
  margin: 0px;
  padding: 0;
  color: ${colors.GREY};
`


export const CartCompBase = ({ closeCartDrawer, cart, isMobile, ...props }) => {
  const [openCheckout, setOpenCheckout] = useState(false);
  const [checkoutWidth, setCheckoutWidth] = useState('400px')
  const [CheckoutTitle, setCheckoutTitle] = useState('Checkout')
  const [showPayment, setShowPayment] = useState(false)


  const cartLength = Object.keys(cart).length - 2;
  const cartExists = cartLength > 0;

  const toggleCheckoutDrawer = () => {
    setOpenCheckout(openCheckout => !openCheckout);
  };

  const ConfigureCheckoutScreen = () => {
    setCheckoutWidth('700px')
    setCheckoutTitle('Make Payment')
    setShowPayment(true)
  }

  const handleBackPress = () => {
    setCheckoutWidth('400px')
    setCheckoutTitle('Checkout')
    setShowPayment(false)
  }

  return (
    <Wrapper>
      <Affix offsetTop={70}>
        <>
          {cartExists && (
            <HeaderArea>
              <SideTitle>My Shopping Bag</SideTitle>
              <Space />
              <SideSubtitle>{cartLength} ITEMS in bag</SideSubtitle>
            </HeaderArea>
          )}

          <Space size="5em" />
          {cartExists ?
            <>
              {Object.values(cart).map((item, index) => {
                if (!item.title) return null;
                return (
                  <CartItem
                    item={item}
                    key={index}
                    quantity={item.qty}

                  />
                );
              })}
              {/* <CartItem /> */}
              <CheckoutBtnComp total={cart.total} action={toggleCheckoutDrawer} />

            </>
            :
            <EmptyCart />
          }
        </>

      </Affix >

      {/* The Checkout Drawer */}
      <AppDrawer
        headerStyle={{ textAlign: 'center', }}
        width={checkoutWidth}
        title={CheckoutTitle}
        placement="right"
        toggleDrawer={toggleCheckoutDrawer}
        open={openCheckout}
        maskClosable={false}
        isMobile={isMobile}

      >
        <CheckoutComp
          action={ConfigureCheckoutScreen}
          showPayment={showPayment}
          backBtnAction={handleBackPress}
          closePayment={handleBackPress}
          closeCheckoutDrawer={toggleCheckoutDrawer}
          closeCartDrawer={closeCartDrawer}
          isMobile={isMobile}
          width={checkoutWidth}
        />
      </AppDrawer>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userData: state.user,
    extraItems: state.cart.extra,
    orderLocation: state.location.orderLocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    placeOrder: (status) => dispatch(placeOrder(status)),
    setLoader: (status) => dispatch(appLoader(status)),
    resetCart: () => dispatch(resetCart()),
    addCustomization: (item) => dispatch(addCustomization(item)),
    addOrderLocation: (data) => dispatch(addOrderLocation(data)),
  };
};

export const CartComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartCompBase);