import React from 'react'
import styled from 'styled-components';
import { themeConstants } from '../config/theme';

const Wrapper = styled.div`
  box-sizing: border-box;
  border: 0.5px solid rgba(0,0,0,0.5);
  width: 100%;
  padding: 20px;
  cursor: pointer;

  &:hover{
      box-shadow: ${props => props.hover ? themeConstants.BOX_SHADOW1 : 'none'};
      border: ${props => props.hover ? 'none' : 'inherit'};
  }
`


export const CardComp = ({ hover, children, ...props }) => {

  return (
    <Wrapper
      hover={hover}
      bodyStyle={{ padding: 0 }}
    >
      {children}
    </Wrapper>
  )
}
