import React, { useState } from 'react';
import styled from 'styled-components';
import { Popconfirm } from 'antd';

import { Flex } from '../styles/universalStyles';
import { Tag } from './Tag';

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
`;

const TagView = styled.div`
  margin-top: 6px;
`;

const RadioBtn = ({ initial, capitalize, data, ...props }) => {
  const [active, setActive] = useState(initial ? 1 : 0);

  const handleOnClick = (item, index) => {
    setActive(index);

    !!props.action && props.action(item);
  };

  return (
    <Wrapper>
      {data.map((item, index) => (
        <TagView key={index}>
          <Tag
            capitalize={capitalize}
            tagAction={() => handleOnClick(item, index + 1)}
            active={index + 1 === active}
            tagText={item.title}
          />
        </TagView>
      ))}
    </Wrapper>
  );
};

export { RadioBtn };




