import { SAVE_MENU } from '../config/type';

export const menu = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_MENU:
      return [...payload];

    default:
      return state;
  }
};
