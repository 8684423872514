import React from 'react'
import styled from 'styled-components'
import { Space } from '../../styles/universalStyles'
import { FormGroupComp } from '../../components/FormGroup'
import { AppButton } from '../../components/AppButtons'


const Wrapper = styled.div`
  margin: 10em auto;
  background-color: rgba(255,255,255,0.5);
  width: 50%;
  padding: 7em 1em;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.5);
`

const Title = styled.h3`
  font-size: 24px;
  text-align: center;
`

const Form = styled.form`
  width: 45%;
  margin: auto;
`

const AddAdmin = () => {
  return (
    <Wrapper>
      <Title>Welcome Admin: Login to continue</Title>

      <Form>
        <FormGroupComp
          labelName="Name"
          placeholder="name"
          inputType="name"
          required
        />

        <Space size="30px" />

        <FormGroupComp
          labelName="Email"
          placeholder="email"
          inputType="email"
          required
        />

        <AppButton>
          Invite User
        </AppButton>
      </Form>

    </Wrapper>
  )
}


export { AddAdmin }