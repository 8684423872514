import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Typography } from 'antd';
import { UserOutlined, ShoppingCartOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'

import { Flex, SpaceBetween, Space } from '../../styles/universalStyles';
import { LocationComp } from './LocationComp';
import { AccountComp } from './AccountComp';
import { CartComp } from './CartComp';
import { colors } from '../../config/theme';
import { connect } from 'react-redux';
import { cartItemCountFormatter, getUserFirstname } from '../../services/util/formatter';
import { AppDrawer } from '../Drawers';
import { device } from '../../util/devices';
import { useMediaQuery } from '../../services/customHooks/useMediaQuery';



const { Text } = Typography;


const Wrapper = styled.div`
  width: 80vw;
  margin: auto;
  padding: 15px 0;
  max-width: 1200px;

   @media ${device.mobile} { 
    width: 100vw;
    padding: 10px 15px;
  }
`

const Location = styled(Text)`
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
  margin-right: 5px;
`

const MenuItemWrap = styled(Flex)`
  gap: 5px;
  margin-left: 15px;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;

  &:hover{
    color: ${colors.PRIMARY}
  }
`

const MenuItem = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`

const LogoWrap = styled.div`
  width: 100px;
  height: 70px;
  cursor: pointer;
`

const LogoImg = styled.img`
  width: 100%;
  height: 100%;
`

const CartBadge = styled.p`
  background-color: ${colors.LIGHT_PRIMARY};
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 8px;
`



export const NavbarBase = ({ user, cart, deliveryLocation, ...props }) => {

  const [openLocation, setOpenLocation] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  const isMobile = useMediaQuery(device.mobile);
  const navigate = useNavigate();

  const toggleLocationDrawer = () => {
    setOpenLocation(openLocation => !openLocation);
  };
  const toggleLoginDrawer = () => {
    setOpenLogin(openLogin => !openLogin);
  };
  const toggleCartDrawer = () => {
    setOpenCart(openCart => !openCart);
  };

  const userExists = Object.keys(user).length > 1;

  const cartLength = Object.keys(cart).length - 2;
  const cartExists = cartLength > 0;

  const userName = userExists ? `Hi, ${getUserFirstname(user.displayName || "User")}` : "Login"


  return (
    <Wrapper>
      {/* logo */}
      <SpaceBetween>
        <LogoWrap onClick={() => navigate('/')}>
          <LogoImg src="/logoWeb.png" />
        </LogoWrap>

        <Flex>
          <MenuItemWrap onClick={toggleLoginDrawer}>
            <UserOutlined />
            {
              !isMobile && (<MenuItem>{userName}</MenuItem>)
            }

          </MenuItemWrap>

          <MenuItemWrap onClick={toggleCartDrawer}>
            <ShoppingCartOutlined />

            {cartExists ? <CartBadge>{cartLength}</CartBadge> : <MenuItem>Cart</MenuItem>}

          </MenuItemWrap>
        </Flex>

      </SpaceBetween>

      {/* Location Drawer */}
      <AppDrawer
        toggleDrawer={toggleLocationDrawer}
        open={openLocation}
        placement="left"
        width={isMobile ? '100%' : '500px'}
        isMobile={isMobile}
      >
        <LocationComp />
      </AppDrawer>


      {/* Profile Drawer */}
      <AppDrawer
        toggleDrawer={toggleLoginDrawer}
        open={openLogin}
        placement="right"
        width={isMobile ? '100%' : '400px'}
        title={"Profile"}
        isMobile={isMobile}

      >
        <AccountComp
          userName={userExists && userName}
          close={toggleLoginDrawer}
        />
      </AppDrawer>

      {/* Cart Drawer */}
      <AppDrawer
        headerStyle={{ textAlign: 'left', }}
        toggleDrawer={toggleCartDrawer}
        open={openCart}
        placement="right"
        width={isMobile ? '100%' : '500px'}
        title={`${cartItemCountFormatter(cartLength)} in your Cart`}
        isMobile={isMobile}
      >
        <CartComp closeCartDrawer={toggleCartDrawer} isMobile={isMobile} />
      </AppDrawer>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {

  return {
    user: state.user,
    cart: state.cart,
    deliveryLocation: state.location.orderLocation || {},
  };
};

export const Navbar = connect(mapStateToProps, null)(NavbarBase);
