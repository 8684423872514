import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, InputNumber } from 'antd';
import { colors } from '../config/theme';


const Wrapper = styled(Input)`
  padding: 10px;
  border-radius: 0px;
  /* box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2); */
  border: none;
  width: ${props => props.width || "100%"};
  font-size: 16px;
  letter-spacing: 1px;
  border: 1px solid rgba(0,0,0,0.2);


  &:focus{
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,0.2);
  }
`
const NumberWrap = styled(InputNumber)`
  padding: 10px;
  border-radius: 0px;
  box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2);
  border: none;
  width: 100%;

  &:focus{
    border: 1px solid rgba(0,0,0,0.2);
  }
`

export const AppInput = ({ onChange, value, ...props }) => {
  const [localValue, setValue] = useState('')

  const handleOnChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Wrapper
      value={value || localValue}
      onChange={onChange || handleOnChange}
      {...props}
    />
  )
}

export const AppInputNumber = (props) => {
  return (
    <NumberWrap {...props} min={1} max={10} />
  )
}