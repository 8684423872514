import React from 'react'
import { Drawer } from 'antd';


export const AppDrawer = ({
  toggleDrawer,
  placement,
  children,
  open,
  headerStyle,
  title,
  width,
  bodyStyle,
  isMobile,
  ...props
}) => {

  return (
    <Drawer
      headerStyle={headerStyle || headStyle}
      width={isMobile ? '100%' : (width || '30%')}
      title={title || "Set Delivery Location"}
      placement={placement || "left"}
      onClose={toggleDrawer}
      open={open}
      bodyStyle={bodyStyle || bodyStyler}
      {...props}
    >

      {children}

    </Drawer>
  )
}

const bodyStyler = {
  padding: '10px',
}

const headStyle = {
  textAlign: 'center'
}
