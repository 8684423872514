import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AppText, Space } from '../../styles/universalStyles'
import { FormGroupComp } from '../../components/FormGroup'
import { AppButton } from '../../components/AppButtons'
import { createUserWithEmail, loginUserInWithEmail, saveUserData } from '../../services/util/user'
import { capitalizeFormatter } from '../../services/util/formatter'
import { colors } from '../../config/theme'
import { auth } from '../../config/firebase'


const Wrapper = styled.div`
  margin: 10em auto;
  background-color: rgba(255,255,255,0.5);
  width: 50%;
  padding: 7em 1em;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.5);
`

const Title = styled.h3`
  font-size: 24px;
  text-align: center;
`

const FormWrapper = styled.form`
  width: 45%;
  margin: auto;
`

const MyAdmin = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)

    const formData = new FormData(event.target)

    const initialData = {
      email: formData.get("email"),
      password: formData.get("password"),
    }

    const { user, error, message } = await loginUserInWithEmail(initialData)

    if (error) {
      setErrorMessage(message)
    }

    console.log({ user })

    event.target.reset();
    setLoading(false)
  }


  useEffect(() => {
    const user = auth.currentUser;
    console.log("from useEffect", { user })
  }, [])


  return (
    <Wrapper>
      <Title>Welcome Admin: Login to continue</Title>

      <FormWrapper id="loginForm" onSubmit={handleLogin}>
        <div style={{ textAlign: "center" }}>
          <AppText color={colors.DANGER}>{errorMessage}</AppText>
          <Space size="2em" />
        </div>
        <FormGroupComp
          labelName="Email"
          placeholder="email"
          inputType="email"
          id="email"
          name="email"
          required
        />

        <Space size="30px" />

        <FormGroupComp
          labelName="Password"
          placeholder="password"
          inputType="password"
          id="password"
          name="password"
          required
        />
        <AppButton>
          {loading ? "Logging in..." : "Login"}
        </AppButton>

      </FormWrapper>







    </Wrapper>
  )
}


export { MyAdmin }