import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';

// BsCartCheckFill
import { AppText, Flex, Space } from '../../styles/universalStyles';
import { BannerComp } from '../../components/BannerComp';
import { MenuItem } from '../../components/MenuItem';
import { colors } from '../../config/theme';
import { capitalizeFormatter, currencyFormatter } from '../../services/util/formatter';
import { CartComp } from '../../components/NavbarComp/CartComp';
import { saveMenu } from '../../services/redux/actions/menu';
import { LoadingComp } from '../../components/LoadingComp';
import { TEXT_CONSTANTS } from '../../services/constants/textconstant';
import { FullscreenLoader } from '../../components/FullscreenLoader';
import { OrderConfirmationComp } from '../../components/OrderConfirmationComp';
import { device } from '../../util/devices';
import { useMediaQuery } from '../../services/customHooks/useMediaQuery';

const Wrapper = styled.div`
`

const ContentWrap = styled(Flex)`
  max-width: 1250px;
  margin: auto;
  align-items: flex-start;
`

const CenterPanel = styled.div`
  flex:3;
  border-right: ${props => props.border ? `1px solid ${colors.LIGHTEST_GREY}` : "none"};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`


const RightPanel = styled.div`
  flex: 1;
  padding: 0 15px;
  transition: 1s ease all;

  @media ${device.mobileS} { 
    display: none;
  }
  @media ${device.laptop} { 
    display: block;
  }
`

export const HomeBase = ({ status, cart, ...props }) => {

  const isMobile = useMediaQuery(device.mobile);

  const menu = props.menu;
  const menuLoaded = menu.length > 0;

  const cartLength = Object.keys(cart).length - 2;
  const cartExists = cartLength > 0;

  return (
    <Wrapper>
      <Space />
      <BannerComp />
      <Space size="2em" />

      <ContentWrap>
        {/* <LeftPanel/> */}

        <CenterPanel border={menuLoaded}>
          {menuLoaded ?
            <>
              {menu.map((data, index) => (
                <MenuItem
                  itemData={data}
                  title={capitalizeFormatter(data.title)}
                  desc={data.desc}
                  amount={currencyFormatter(Number(data.amount))}
                  imgUrl={data.imgUrl}
                  id={data.id}
                  data={data}
                  index={index + 1}
                  key={index + 1} />
              ))}
            </>
            :
            <div style={{ height: "10vh" }}>
              <LoadingComp
                position="flex-start"
                large
                color={colors.PRIMARY}
                text={TEXT_CONSTANTS.MENU_LOADING}
              />
            </div>

          }

        </CenterPanel>

        {(menuLoaded && cartExists) && (
          <RightPanel>
            <CartComp isMobile={isMobile} data={menu} />
          </RightPanel>
        )}

      </ContentWrap>

      {!!status && (<OrderConfirmationComp />)}


    </Wrapper>
  )
}



const mapStateToProps = (state) => {

  return {
    cart: state.cart,
    menu: state.menu,
    status: state.app.orderStatus,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveMenu: (menuItems) => dispatch(saveMenu(menuItems)),
  };
};

export const Home = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeBase);
