import React from 'react'
import styled from 'styled-components'
import { colors } from '../config/theme'
import { AppText, Flex } from '../styles/universalStyles'
import { device } from '../util/devices'

const Wrapper = styled(Flex)`
  height: 400px;
  width: 100%;
  background-color: ${colors.PRIMARY};
  justify-content: center;

  @media ${device.mobile} { 
    height: 300px;
    padding: 0px 15px;
  }
`

export const BannerComp = () => {
  return (
    <Wrapper>
      <AppText fontSize="3em" color={colors.WHITE}>Some Mouth Watering Images will scroll here</AppText>
    </Wrapper>
  )
}
