import { EnvironmentFilled } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../config/theme'
import { capitalizeFormatter, currencyFormatter } from '../services/util/formatter'
import { AppText, Flex, Space, SpaceBetween } from '../styles/universalStyles'

const CheckoutSection = styled.div`
  background-color: ${props => props.color};
  padding: 15px;
  margin-bottom: 1em;
`

export const DisplayAddress = ({ action, color, data, ...props }) => {

  return (
    <CheckoutSection
      color={color || colors.LIGHT_GREEN}
      style={{ cursor: "pointer" }}
      onClick={action}
    >
      <SpaceBetween>
        <Flex>
          <EnvironmentFilled style={{ fontSize: "20px", color: colors.TEAL }} />
          <Space size="3px" />
          <AppText fontSize="18px">Delivery at: {data.tagName} </AppText>
        </Flex>
        <div style={{ marginRight: '10px' }}>
          <AppText color={colors.PRIMARY} bold>EDIT</AppText>
        </div>

      </SpaceBetween>

      <AppText fontSize="14px">{data.deliveryAddress}</AppText>
      <Space size="0px" />
      <AppText fontSize="14px"><strong>Floor:</strong> {capitalizeFormatter(data.floor) || "NA"}</AppText>
      <Space size="0px" />
      <AppText fontSize="14px"><strong>How To Reach:</strong> {data.howToReach || "NA"}</AppText>
      <Space size="0px" />
      <AppText fontSize="14px"><strong>Area:</strong> {capitalizeFormatter(data.areaName)}</AppText>
      {/* <Space size="3px" /> */}
      {/* <AppText small color={colors.LIGHT_PRIMARY} bold>Delivery Charge: {currencyFormatter(1000)}</AppText> */}

    </CheckoutSection>
  )
}
