import React from 'react'
import { LoadingOutlined } from "@ant-design/icons";
import styled from 'styled-components';
import { AppText, Flex } from '../styles/universalStyles';
import { colors } from '../config/theme';

const Wrapper = styled(Flex)`
  gap: 20px;
  justify-content: ${props => props.position ? props.position : "center"};
  height: inherit;
`

const LoadingIcon = styled(LoadingOutlined)`
  font-size: ${props => props.large ? "24px" : props.fontSize ? props.fontSize : "16px"};
  color: ${props => props.color ? props.color : colors.LIGHT_GREY};
`


export const LoadingComp = ({ position, text, large, color, fontSize, ...props }) => {
  return (
    <Wrapper position={position}>
      <LoadingIcon
        large={large}
        color={color}
        fontSize={fontSize}
      />
      <AppText
        color={color}
        large={large}
        fontSize={fontSize}
      >{text || "Loading ..."}</AppText>
    </Wrapper>
  )
}
