import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Icon, { LoadingOutlined, LogoutOutlined, GoogleOutlined, AppleOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { signInWithPopup } from "firebase/auth";
import { connect } from 'react-redux';
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";


import { colors, themeConstants } from '../../config/theme'
import { AppInput, AppInputNumber } from '../AppInput';
import { AppParagraph, AppText, Flex, SectionUnderline, Space, SpaceBetween } from '../../styles/universalStyles';
import { auth, authProvider } from '../../config/firebase';
import { createUser, getCurrentUser, signOutUser } from '../../services/util/user';
import { displayAlert } from '../../util/alerts';
import { AppButton, IconBtn } from '../AppButtons';
import { number_RE, email_RE } from '../../services/util/regex';
import { AppConfirmation } from '../AppConfirmation';
import { validatePhone } from '../../services/util/validations';
import { OTPcomp } from '../OTPcomp';
import { TEXT_CONSTANTS } from '../../services/constants/textconstant';
import { capitalizeFormatter } from '../../services/util/formatter';
import { profileMenu } from '../../data/profileMenu';
import { screenActions } from '../../util/screenActions';
import { DisplayPhoneComp } from '../DisplayPhoneComp';
import { async } from '@firebase/util';
import { DisplayEmailLogin } from '../DisplayEmailLogin';


const Wrapper = styled.div`
  width: 100%;
  padding: 0;
`
const Title = styled.h5`
  font-weight: 400;
  font-size: 25px;
  color: ${colors.PRIMARY};
  margin: 0;
`

const IconWrap = styled.button`
  /* padding: 6px 10px; */
  padding: 4px 3px;
  width: 42px;
  border-radius: 100%;
  box-shadow: ${themeConstants.BOX_SHADOW1};
  cursor: pointer;
  border: 2px solid ${props => props.bgc};
  background-color: transparent;
  display: block;
  margin: auto;

  &:disabled{
    pointer-events: none;
  }
`

const SectionTitle = styled(AppText)`
  color: ${colors.LIGHT_GREY};
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 5px;
`;

const MenuItemWrap = styled(Flex)`
  margin-bottom: 30px;
  cursor: pointer;
`;

const IconViewWrap = styled.div`
  background-color: ${colors.LIGHTER_PRIMARY};
  margin-right: 20px;
  border-radius: 30px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
`;

export const AccountCompBase = ({ user, userName, ...props }) => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  // const [appleLoading, setAppleLoading] = useState(false);
  const [showPhone, setShowPhone] = useState(false)
  const [showMail, setShowMail] = useState(false)
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [number, setNumber] = useState('9160402046');
  const [errorMessage, setErrorMessage] = useState('')
  const [emailError, setEmailError] = useState('')
  const [enableBtn, setEnableBtn] = useState(true)
  const [phoneLoading, setPhoneLoading] = useState(false)



  // TODO: Refactor to use one signin function: consider using custom hooks
  // check if the user can signin with signin-with-email, if the response is false, then use signup-with-email and return the user obj after the process
  const handleGoogleLogin = async () => {
    setGoogleLoading(!googleLoading)

    // start google Auth process
    try {
      const userCred = await signInWithPopup(auth, authProvider);
      const { user } = userCred;

      // create user in firestore
      await createUser();

      // set loading to false
      setGoogleLoading(false)

      if (props.close) {
        props.close();
      }

      return;

    } catch (error) {
      // console.log("An error occured while logging you in: ======>", error)
    }
  }

  //TODO: move to a util function
  const handleEmailSignIn = async () => {
    if (!email_RE.test(email) || !password) {
      setEmailError(true)
      return
    }

    try {
      setEmailError(emailError => !false)

      setMailLoading(true)

      await createUserWithEmailAndPassword(auth, email, password)

      await createUser();

      setMailLoading(mailLoading => !mailLoading)

      if (props.close) {
        props.close();
      }

      return;

    } catch (error) {
      // console.log("An error occured", { error })

    }
  }

  //TODO: move to a util function
  const handleOnPhoneNumberChange = (event) => {

    setEnableBtn(true)

    if (event.target.value === '' || number_RE.test(event.target.value)) {
      setNumber(event.target.value)
      setErrorMessage('')
    }

    // auto validate phone number
    if (event.target.value.length === 10) {
      const error = validatePhone(event.target.value)

      if (error.length > 0) {
        setErrorMessage(error)
      } else {
        setErrorMessage('')
        setEnableBtn(!enableBtn)
      }
    }
  }

  //TODO: move to a util function
  const handlePhoneSignIn = () => {

    setEnableBtn(!enableBtn)
    setPhoneLoading(true)

    try {
      // create the recaptcha
      window.recaptchaVerifier = new RecaptchaVerifier('signInDiv', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
          // console.log("The recaptcha response", { response })
        }
      }, auth);

      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, number, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          // ...
        });
    } catch (error) {
      // console.log("Error occured while creating sms =====>", { error })
    }



  }

  const handleAction = (actionText) => {
    screenActions(actionText, "navigation", userExists);
  };

  const signout = () => {
    signOutUser();

  }


  const userExists = Object.keys(user).length > 1;

  return (
    <Wrapper>
      <Title>{userName || TEXT_CONSTANTS.PROFILE_PAGE.TITLE}</Title>
      <AppParagraph>
        {userExists ?
          userExists && user.phoneNumber
          :
          TEXT_CONSTANTS.PROFILE_PAGE.SUB_TITLE
        }

      </AppParagraph>

      <Space size="2em" />

      {
        userExists ?
          <>
            {
              profileMenu.map((menu, index) => (
                <SectionUnderline key={index}>
                  <Space />
                  <SectionTitle semiBold>
                    {menu.title.toLocaleUpperCase()}
                  </SectionTitle>
                  <Space />
                  {menu.details.map((details, index) => (
                    <div
                      key={index}
                      onClick={() => handleAction(details.action)}
                    >
                      <MenuItemWrap>
                        <IconViewWrap>
                          <Icon
                            color={colors.BLACK}
                            component={details.icon}
                            style={{ fontSize: "20px" }}
                          />
                        </IconViewWrap>
                        <AppText fontSize="15px" color={colors.BLACK} semiBold>
                          {details.title}
                        </AppText>
                      </MenuItemWrap>
                    </div>
                  ))}

                  {/* <Space /> */}
                </SectionUnderline>
              ))
            }
          </>
          :
          <>
            <Flex style={{ gap: 50 }}>
              <div>
                <IconWrap disabled={googleLoading} bgc={colors.PRIMARY} onClick={handleGoogleLogin}>
                  <Icon
                    component={googleLoading ? LoadingOutlined : GoogleOutlined}
                    style={{ fontSize: "24px", color: colors.GOOGLE }}
                  />

                </IconWrap>
                <AppParagraph small>Sign in with Google</AppParagraph>
              </div>

              <div>
                <IconWrap bgc={colors.PRIMARY} onClick={() => setShowMail(!showMail)}>
                  <Icon
                    component={mailLoading ? LoadingOutlined : MailOutlined}
                    style={{ fontSize: "24px", color: colors.LIGHT_GREY }}
                  />
                </IconWrap>
                <AppParagraph small>Sign in with Email</AppParagraph>
              </div>
            </Flex>
            {
              showMail && (
                <DisplayEmailLogin
                  email={email}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  password={password}
                  emailError={emailError}
                  handleEmailSignIn={handleEmailSignIn}
                  mailLoading={mailLoading}

                />
              )
            }
          </>
      }

      <Space size="2em" />
      {
        showPhone && (
          <DisplayPhoneComp
            number={number}
            handleOnPhoneNumberChange={handleOnPhoneNumberChange}
            handlePhoneSignIn={handlePhoneSignIn}
            enableBtn={enableBtn}
            phoneLoading={phoneLoading}
            errorMessage={errorMessage}
          />)
      }


      {userExists && (
        <MenuItemWrap onClick={signout}>
          <IconViewWrap>
            <Icon
              color={colors.BLACK}
              component={LogoutOutlined}
              style={{ fontSize: "20px" }}
            />
          </IconViewWrap>
          <AppText fontSize="15px" color={colors.BLACK} semiBold>
            {TEXT_CONSTANTS.LOGOUT}
          </AppText>
        </MenuItemWrap>
      )}

    </Wrapper>
  )
}







const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export const AccountComp = connect(mapStateToProps, null)(AccountCompBase);