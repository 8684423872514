import React, { useState } from 'react'
import { Popconfirm } from 'antd';

export const AppConfirmation = ({ children, title, description, confirm, okText, cancelText, ...props }) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={confirm}
      okText={okText || "Continue"}
      cancelText={cancelText || "Cancel"}
    >
      <>
        {children}
      </>
    </Popconfirm>
  )
}

export const AsyncAppConfirmation = ({ children, title, description, confirm, okText, cancelText, ...props }) => {
  const [open, setOpen] = useState(props.open);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // const showPopconfirm = () => {
  //   setOpen(true);
  // };

  const handleOk = async () => {
    setConfirmLoading(true);

    await confirm();

    setOpen(false);
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    setOpen(false);
  };


  return (
    <Popconfirm
      title={title}
      description={description}
      okText={okText || "Continue"}
      cancelText={cancelText || "Cancel"}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      {/* <> */}
      {children}
      {/* </> */}
    </Popconfirm>
  )
}
