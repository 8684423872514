import {
  ADD_EXTRA_ITEM,
  ADD_TO_CART,
  DELETE_EXTRA_ITEM,
  DELETE_ITEM,
  RESET_CART,
  UPDATE_CART,
  ADD_CUSTOMIZATION,
} from '../config/type';

const initialState = {
  total: 0,
  customization: 'ripe',
};

export const cart = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_CART:
      const item = {
        [payload.index]: payload,
      };
      state.total = state.total + Number(payload.amount);
      return { ...state, ...item };

    case UPDATE_CART:
      const index = payload.item.index;
      const cartItem = {
        [index]: payload.item,
      };

      if (payload.ops === '+') {
        state.total = state.total + Number(payload.item.amount);
      } else {
        state.total = state.total - Number(payload.item.amount);
        if (payload.item.qty === 0) {
          if (delete state[index]) {
            return { ...state };
          }
        }
      }
      return { ...state, ...cartItem };
    case DELETE_ITEM:
      const amount = Number(payload.amount) * payload.qty;
      state.total = state.total - amount;
      delete state[payload.index];
      return { ...state };

    case RESET_CART:
      return {
        customization: 'ripe',
        total: 0,
      };
    case ADD_CUSTOMIZATION:
      return {
        ...state,
        customization: payload,
      };

    default:
      return state;
  }
};
