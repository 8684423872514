import Geocoder from 'react-native-geocoding';


export const geocodeCordinates = async (cordinates) => {
  try {
    const geoData = cordinates;

    const address = await Geocoder.from({
      latitude: geoData.lat,
      longitude: geoData.lng,
    });

    return address.results[0].formatted_address;
  } catch (error) {
    // console.log('An error occured while getting address data', error);
  }
};