import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { connect } from 'react-redux';


import { colors, themeConstants } from '../config/theme'
import img from '../assets/images/titus.jpeg'
import { AppBtn, AppText, Flex, Space, SpaceBetween } from '../styles/universalStyles'
import { MenuItemAddBtn } from './MenuItemButton';
import { addToCart, updateCart } from '../services/redux/actions/cart';
import { truncateStr } from '../services/util/formatter';
import { device } from '../util/devices';


const Wrapper = styled(Flex)`
  box-sizing: border-box;
  padding: 15px;
  margin: auto;
  margin-bottom: 20px;
  gap: 15px;
  align-items: flex-start;
  border-bottom: 0.5px solid ${colors.LIGHTEST_GREY};
  padding-bottom: 40px;
`

const GroupImageWrap = styled.div`
  position: relative;
`

const ImageWrap = styled.div`
  width: 118px;
  height: 98px;
  border-radius: 15px;
  object-fit: contain;

  &:hover{
    cursor: pointer;
  }
`
const Image = styled.img`
  width: inherit;
  height: inherit;
  border-radius: 10px;
`
const MenuDescWrap = styled.div`
  flex: 2;
`

const MenuTitle = styled.h6`
  margin: 0;
  font-weight: 450;
  font-size: 18px;
  margin-bottom: 2px;
  letter-spacing: 0.1em;
`
const MenuDesc = styled.p`
  margin: 0;
  margin-bottom: 15px;
  line-height: 1.5em;
  color: ${colors.LIGHT_GREY};
`
const MenuAmount = styled(MenuTitle)`
  margin: 0;
  margin-bottom: ${props => props.modal ? "15px" : "30px"};
  font-size: 14px;
`
const ModalWrap = styled.div`
`
const ModalImage = styled.img`
  width: 100%;
  border-radius: 15px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
`

const BtnWrapper = styled.div`
  width: 90%;
  position: ${props => props.position ? props.position : "absolute"};
  bottom: -15px;
  left: 4px;
`


const MenuItemWrap = styled.div`
  margin-bottom: 30px;
  padding-bottom: 15px;
  transition: all 0.4s ease;

  &:hover{
    box-shadow: ${themeConstants.BOX_SHADOW1};
  }

  @media ${device.mobileS} { 
    width: 90%;
    box-shadow: ${themeConstants.BOX_SHADOW1};
    border-radius: 10px;
  }

  @media ${device.tablet} { 
    width: 40%;

    box-shadow: ${themeConstants.BOX_SHADOW1};
  }
  @media ${device.laptop} { 
    width: 30%;

    box-shadow: none;
  }
`


const HeaderSection = styled.div`
  position: relative;
  width: 100%;

`

const MenuImage = styled.img`
  object-fit: cover;
  cursor: pointer;

  width: inherit;
  height: 200px;
  box-shadow: ${themeConstants.BOX_SHADOW1};
  
`

const ItemTitle = styled(AppText)`
  font-weight: 700;
  color: rgba(0,0,0,0.8);
`

const ContentSection = styled.div`
  margin: auto;
  width: 100%;
`


const BtnWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`
const ViewText = styled(AppText)`
  padding-bottom: 2px;
  border-bottom: 1px dotted ${colors.LIGHTER_GREY};
  cursor: pointer;
`



export const MenuItemBase = ({ itemQty, updateCart, addToCart, itemData, title, desc, amount, imgUrl, id, index, ...props }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  const updateCount = (count) => {
    setItemCount(itemCount + Number(count));
    const cartItem = {
      ...itemData,
      qty: 1,
      index
    };
    addToCart(cartItem);
  };

  const updateCartItems = (count) => {
    setItemCount(itemCount + Number(count));
    const cartItem = {
      ...itemData,
      qty: itemCount + Number(count),
      index
    };

    if (count === 1) {
      updateCart(cartItem, '+');
    } else {
      updateCart(cartItem, '-');
    }
  };


  useEffect(() => {
    setItemCount(0)
  }, [itemQty])




  return (
    <>
      <MenuItemWrap>
        <HeaderSection>
          <MenuImage
            src={imgUrl}
            onClick={() => setModalOpen(true)}
          />
          <BtnWrap>
            <MenuItemAddBtn
              action={updateCartItems}
              initialAction={updateCount}
              itemCount={itemCount}

            />
          </BtnWrap>
        </HeaderSection>

        <Space />
        <ContentSection>
          <Space>
            <SpaceBetween>
              <div><ItemTitle fontSize="15px" >{title}</ItemTitle></div>
              <div><ItemTitle >{amount}</ItemTitle></div>
            </SpaceBetween>

            <Space />
            <AppText color="rgba(0,0,0,0.8)" >{truncateStr(desc)}</AppText>

            <Space />
            <ViewText
              color="rgba(0,0,0,0.8)"
              onClick={() => setModalOpen(true)}
            >View+</ViewText>

          </Space>


        </ContentSection>
      </MenuItemWrap>



      <Modal
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        closable={false}
      >
        <ModalContent
          title={title}
          desc={desc}
          amount={amount}
          imgUrl={imgUrl}
          id={id}
          action={updateCartItems}
          initialAction={updateCount}
          itemCount={itemCount}
        />

      </Modal>
    </>
  )
}


const ModalContent = ({ itemCount, initialAction, action, title, desc, amount, imgUrl, id, ...props }) => {

  return (
    <ModalWrap>
      <ModalImage src={imgUrl} />

      <SpaceBetween style={{ alignItems: 'flex-start' }}>
        <div>
          <MenuTitle>{title}</MenuTitle>
          <MenuAmount modal>
            {amount}
          </MenuAmount>
        </div>

        <MenuItemAddBtn
          action={action}
          initialAction={initialAction}
          itemCount={itemCount}
        />
      </SpaceBetween>
      <MenuDesc>
        {desc}
      </MenuDesc>

    </ModalWrap>
  )
}


const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    itemQty: state.app.itemQty,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    updateCart: (item, ops) => dispatch(updateCart(item, ops)),
  };
};

export const MenuItem = connect(mapStateToProps, mapDispatchToProps)(MenuItemBase);
