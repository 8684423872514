export const SAVE_MENU = 'SAVE_MENU';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_ITEM = 'DELETE_ITEM';
export const RESET_CART = 'RESET_CART';
export const ADD_EXTRA_ITEM = 'ADD_EXTRA_ITEM';
export const DELETE_EXTRA_ITEM = 'DELETE_EXTRA_ITEM';
export const SET_APP_LOADER = 'SET_APP_LOADER';
export const RESET_ITEM_COUNT = 'RESET_ITEM_COUNT';
export const ORDER_STATE = 'ORDER_STATE';
export const SAVE_USER = 'SAVE_USER';
export const ADD_CUSTOMIZATION = 'ADD_CUSTOMIZATION';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const GET_ORDERS = 'GET_ORDERS';
export const LOCATION_SEARCH_ERROR = 'LOCATION_SEARCH_ERROR';
export const SAVE_ORDER_LOCATION = 'SAVE_ORDER_LOCATION';
