import styled from "styled-components";
import { Typography } from 'antd';
import { colors } from "../config/theme";

const { Text } = Typography;


export const Flex = styled.div`
  display: flex;
  align-items: center;
`

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
`

export const Space = styled.div`
  margin: ${props => props.size || '10px'};
`

export const AppBtn = styled.button`
  flex-direction: row;
  border-style: solid;
  justify-content: center;
  align-items: center;
  border-width: 0.5px;
  border-color: ${colors.PRIMARY};
  border-radius: 5px;
  /* padding-vertical: ${props => props.padding ? "10px" : "5px"}; */
  background-color: ${(props) => (props.fill ? colors.PRIMARY : colors.WHITE)};
  cursor: pointer;
`;

// App text
export const AppText = styled(Text)`
  font-family: 'Mulish';
  font-size: ${(props) =>
    props.small
      ? '12px'
      : props.large
        ? '24px'
        : props.fontSize
          ? props.fontSize
          : '14px'};
  color: ${(props) =>
    props.PRIMARY ? colors.PRIMARY : props.color ? props.color : colors.BLACK};

  font-weight: ${(props) => (props.bold || props.semiBold ? 'bold' : 'normal')};

  text-align: ${(props) =>
    props.center ? 'center' : props.right ? 'right' : 'left'};
`;

// App Paragraph
export const AppParagraph = styled.p`
  font-family: 'Mulish';
  font-size: ${(props) =>
    props.small
      ? '12px'
      : props.large
        ? '24px'
        : props.fontSize
          ? props.fontSize
          : '14px'};
  color: ${(props) =>
    props.PRIMARY ? colors.PRIMARY : props.color ? props.color : colors.BLACK};

  font-weight: ${(props) => (props.bold || props.semiBold ? 'bold' : 'normal')};

  text-align: ${(props) =>
    props.center ? 'center' : props.right ? 'right' : 'left'};
`;

export const SectionUnderline = styled.div`
  border-bottom: 0.5px solid ${colors.LIGHTER_GREY};
`;