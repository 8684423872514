import React from 'react'
import styled from 'styled-components'
import { AiOutlineCaretRight } from "react-icons/ai";
import { colors } from '../config/theme'
import { Flex, SpaceBetween, Space } from '../styles/universalStyles'
import { currencyFormatter } from '../services/util/formatter';


const CheckoutBtnWrap = styled.div`
  background-color: ${colors.LIGHTER_PRIMARY};
  padding: 15px 10px;
`

const CheckoutTitle = styled.h5`
  padding: 0;
  margin: 0;
  font-weight: 500;
`
const CheckoutSubTitle = styled.h6`
  padding: 0;
  margin: 0;
  margin-top: 2px;
  font-weight: 300;
  font-size: 13px;
  color: ${colors.LIGHT_GREY};
  margin-bottom: 2em;
`

const CheckoutBtn = styled(Flex)`
  background-color: ${colors.PRIMARY};
  padding: 18px 10px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-weight: 500;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
`


export const CheckoutBtnComp = ({ btnText, total, title, subtitle, ...props }) => {
  return (
    <CheckoutBtnWrap>
      <SpaceBetween>
        <CheckoutTitle>{title || "Subtotal"}</CheckoutTitle>
        <CheckoutTitle>{currencyFormatter(total) || "N 23, 000"}</CheckoutTitle>
      </SpaceBetween>
      <CheckoutSubTitle>{subtitle || "Extra charges may apply"}</CheckoutSubTitle>

      {/* <Space size="2em" /> */}

      <CheckoutBtn onClick={props.action}>
        {btnText || "Checkout"}
        <AiOutlineCaretRight />
      </CheckoutBtn>
    </CheckoutBtnWrap >
  )
}