

import { RouterProvider } from 'react-router-dom'
import { router } from './config/router';
import './index.css';




function App() {

  if (process.env.NODE_ENV === "production") {
    console.log = () => { }
    console.error = () => { }
    console.warn = () => { }
    console.info = () => { }
    console.debug = () => { }
  };


  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
