
import { serverTimestamp } from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { Alert } from 'antd';

import { auth, database } from '../../config/firebase';


import { saveUserToStore, signoutUser } from '../redux/actions/user';
import { store } from '../redux/config/store';
import { firebaseGetSingleDoc, firebasePostDoc, firebaseUpdateDoc } from "./apiRequests";
import { getAsyncData } from "./asyncStorage";


// Create a user in firestore
export const createUser = async () => {
  const user = await getUserData();
  if (!user) {
    saveUserData();
  }
};

// Creates user with email and password and signs them in
export const createUserWithEmail = async (initialData) => {

  const { email, password, displayName } = initialData;

  let returnValue = {
    success: false,
    error: false
  }

  try {
    // create user
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    const { user } = userCredential;

    returnValue = {
      success: true,
      error: false,
      user: {
        ...user,
        displayName,
      }
    }
    return returnValue;

  } catch (error) {

    // TODO: Move this to a utility function to accomadte normal user sign in: https://stackoverflow.com/questions/51562995/how-can-i-check-if-user-exists-in-firebase
    const errorMessage = error?.code.split("/")[1].split("-").join(" ")

    returnValue.error = true;
    returnValue.message = errorMessage;

    return returnValue;
  }

}

// Signs a user in with email and password

export const loginUserInWithEmail = async (data) => {
  const { email, password } = data
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const { user } = userCredential;

    return user
  } catch (error) {
    // TODO: DRY!!!! check createUserWithEmail function 
    const errorMessage = error?.code.split("/")[1].split("-").join(" ")
    return { error: true, message: errorMessage }

  }

}


/**
 *
 * @param {Object} userData data to be saved in firestore
 * This function saves the users' data
 */

export const saveUserData = async (userData) => {

  try {
    const userInfo = userData || getCurrentUser();

    // format the data to remove unsupported fields
    const { proactiveRefresh, auth, stsTokenManager, metadata, ...others } = userInfo

    const data = { ...others, address: {}, signupDate: serverTimestamp() };

    await firebasePostDoc('users', userInfo.uid, data)


    store.dispatch(saveUserToStore(data));


  } catch (error) {
    console.log("from save user", { error })
  }

  return;
};


// get current  authenticated user
export const getCurrentUser = () => {
  const user = auth.currentUser;
  if (!user) return null;

  return user;
};


/**
 * returns the user data from firestore
 */
export const getUserData = async () => {

  const userData = await getCurrentUser();

  if (!userData) return;

  try {

    const { uid } = userData;

    if (!uid) return;

    const user = await firebaseGetSingleDoc('users', uid)

    if (!user) {
      return null
    }
    else {
      store.dispatch(saveUserToStore(user));
      return user;
    }

  } catch (error) {
    // console.log('Error occured while fetching from firestore', error);
  }

  return;
};



/**
 *
 * @param {Object} data for the update operation
 * Also dispatches the returned userData to the store
 */
export const updateUser = async (data) => {
  const { uid } = getCurrentUser();

  try {
    await firebaseUpdateDoc('users', data, uid)

    // update the store with the current user data
    return await getUserData();

  } catch (error) {
    // console.log('An error occured while updating the user collection', { error });
  }
};


const userInfo = (info) => {
  return info
}


// signout user
export const signOutUser = async () => {
  try {
    await signOut(auth)
    store.dispatch(signoutUser());
    <Alert message="You are successfully logged out" type="success" />
  } catch (error) {
    <Alert message="An error occured while signing you out" type="error" />
  }
};

// Listen to auth change
// export const onAuthStateChanged = (user) => {
//   if (!user) return false;
//   return user;
// };




// TODO: remove the delivery charge from the user location. Reasons behind this????!!!

/**
 * 
 * @param {Object} userData User's data
 * @param {Object} orderLocation the order location
 * @returns the deliveryCharge for the order
 */
export const getDeliveryCharge = (userData, orderLocation) => {
  return userData?.address?.details?.locationArea?.deliveryCharge ||
    orderLocation?.deliveryCharge || 0;
}



