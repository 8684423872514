import { SAVE_USER, SIGNOUT_USER } from '../config/type';

export const saveUserToStore = (user) => (dispatch) => {
  try {
    dispatch({
      type: SAVE_USER,
      payload: user,
    });
  } catch (error) {
    // console.log('An error occured while saving user to store', error);
  }
};

export const signoutUser = () => (dispatch) => {
  try {
    dispatch({
      type: SIGNOUT_USER,
    });
  } catch (error) {
    // console.log('An error occured while logging out', error);
  }
};
