import React, { useState } from 'react'
import styled from 'styled-components'
import { EnvironmentOutlined } from '@ant-design/icons'
import { Input, Modal } from 'antd';
import { connect } from 'react-redux';

import { AppText, Flex, SectionUnderline, Space } from '../styles/universalStyles'
import { colors } from '../config/theme'
import { RadioBtn } from './RadioBtn';
import { servingLocation } from '../services/data/servingLocation';
import { capitalizeFormatter } from '../services/util/formatter';
import { displayAlert } from '../util/alerts';
import { locationTags } from '../services/data/locationTags';
import { AppButton } from './AppButtons'
import { addOrderLocation } from '../services/redux/actions/location';
import { storeAsyncData } from '../services/util/asyncStorage';

const Wrapper = styled.div`
  padding: 15px 0;
  `
const SectionDiv = styled(SectionUnderline)`
  /* width: 100%; */
  padding: 10px 15px;
  margin-bottom: 15px;
`

export const EditLocationBase = ({ userData, data, closeDrawer, deliveryLocation, ...props }) => {
  const [locationArea, setLocationArea] = useState('');
  const [openModal, setOpenModal] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [locationAreaError, setLocationAreaError] = useState(false)
  const [newTag, setNewTag] = useState(false);
  const [tagName, setTagName] = useState('');

  const [floor, setFloor] = useState('');
  const [howToReach, setHowToReach] = useState('');
  const [landmark, setLandmark] = useState('');

  const userExists = Object.keys(userData).length > 1;




  const handleLocationArea = (data) => {
    const streetName = data.streetNames.join(', ');
    const deliveryCharge = data.amount;
    const deliveryAreaInfo = {
      streetName,
      areaName: data.title,
      deliveryCharge,
    };

    setModalContent(deliveryAreaInfo)

    setOpenModal(true)
  };

  const handleLocationTagSelection = (data) => {
    setTagName(data.title);
    setNewTag(false);
    if (data.title === 'Other') {
      setNewTag(true);
      setTagName('');
    }
  };

  const handleClickAction = () => {
    setLocationAreaError(false)
    setOpenModal(false)
    setLocationArea(modalContent)
  }

  const handleSaveAddress = async () => {

    if (!locationArea) {

      setLocationAreaError(true)
      return;
    }

    const addressData = {
      deliveryAddress: data,
      howToReach,
      floor,
      landmark,
      areaName: locationArea.areaName,
      deliveryCharge: locationArea.deliveryCharge,
    };

    if (userExists) {
      // console.log("data to serve", { addressData })

      // return saveUserAddressToFirestore();
    }
    return saveUserAddressToLocal(addressData);
  };


  const saveUserAddressToLocal = (data) => {
    // Save to async Storage
    storeAsyncData('location', data);

    // Save to redux
    props.addOrderLocation(data);

    // Close the drawer
    closeDrawer()
  }


  return (
    <Wrapper>

      <SectionDiv>
        <div>
          <AppText fontSize="13px" color={colors.LIGHT_GREY}>Selected Location</AppText>
        </div>
        <Flex>
          <EnvironmentOutlined />
          <Space size="5px" />
          <AppText>{data}</AppText>
        </Flex>
      </SectionDiv>

      <SectionDiv>
        <div>
          <AppText fontSize="13px" color={colors.LIGHT_GREY}>Gate/Floor/Door Number (Optional)</AppText>
        </div>
        <Input
          onChange={(e) => setFloor(e.target.value)}
          value={floor}
          placeholder="Enter your gate/floor/door number (Optional)"
          bordered={false} />
      </SectionDiv>

      <SectionDiv>
        <div>
          <AppText fontSize="13px" color={colors.LIGHT_GREY}>How to reach (Optional)</AppText>
        </div>
        <Input
          value={howToReach}
          onChange={(e) => setHowToReach(e.target.value)}
          placeholder="How to reach(Optional)"
          bordered={false} />
      </SectionDiv>

      <SectionDiv>
        <div>
          <AppText fontSize="13px" color={colors.LIGHT_GREY}>Landmark (Optional)</AppText>
        </div>
        <Input
          value={landmark}
          onChange={(e) => setLandmark(e.target.value)}
          placeholder="Enter a landmark if possible(Optional)" bordered={false}
        />
      </SectionDiv>

      <SectionDiv>
        <div>
          <AppText fontSize="13px" >Select Area in Lagos </AppText>
          <AppText fontSize="13px" color={colors.DANGER}>*</AppText>

        </div>
        <RadioBtn
          action={handleLocationArea}
          data={servingLocation}
          // initial
          capitalize
        />

        {locationAreaError && <AppText small color={colors.DANGER}>Please select the area your address is located in so your delicious food can reach you</AppText>}
      </SectionDiv>

      {/* Show this section if user exists */}
      {userExists && (
        <SectionDiv>
          <div>
            <AppText fontSize="13px" >Save this location for later{' '} </AppText>
            <AppText fontSize="13px" color={colors.DANGER}>*</AppText>

          </div>
          <RadioBtn
            action={handleLocationTagSelection}
            data={locationTags}
            // initial
            capitalize
          />

          {newTag && (
            <>
              <Space size="2em" />

              <AppText small>How should we save this Location?</AppText>
              <Input
                bordered={false}
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                placeholder="E.g Femi's"

                autoFocus
              />
            </>
          )}
        </SectionDiv>
      )}



      <Modal
        title={`Streets in ${capitalizeFormatter(modalContent.areaName)} Area:`}
        open={openModal}
        onOk={handleClickAction}
        onCancel={handleClickAction}
      >
        <AppText>{capitalizeFormatter(modalContent.streetName)}</AppText>
      </Modal>

      <Space size="5em" />

      <Space size="20px">
        <AppButton
          action={handleSaveAddress}
        // action={closeDrawer}
        >
          Save Address
        </AppButton>
      </Space>


    </Wrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addOrderLocation: (data) => dispatch(addOrderLocation(data)),
  };
};

const mapStateToProps = state => {
  return {
    // cart: state.cart,
    userData: state.user,
    deliveryLocation: state.location.orderLocation || {},
    // extraItems: state.cart.extra,
    // orderLocation: state.location.orderLocation,
  }
}

export const EditLocation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLocationBase);
