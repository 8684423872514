import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 *
 * @param {String} key
 * @param {Object} value
 */
export const storeAsyncData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (error) {
    // console.log('An error occured while saving data to async storage', error);
  }
};

/**
 *
 * @param {string} key
 */
export const getAsyncData = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error) {
    // console.log('An error occured while saving data to async storage', error);
  }
};

/**
 *
 * @param {String} key
 */
export const removeAsyncData = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    // console.log('An error occured while saving data to async storage', error);
  }
};
