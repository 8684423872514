import {
  HistoryOutlined,
  FileTextOutlined,
  FormOutlined,
  CustomerServiceOutlined,
  ShareAltOutlined,
  HeartOutlined
} from "@ant-design/icons";

export const profileMenu = [
  {
    title: 'Boli orders',
    details: [
      {
        title: 'Your orders',
        icon: HistoryOutlined,
        action: 'history',
      },
      {
        title: 'Favorite orders',
        icon: HeartOutlined,
        action: 'favourite',
      },
    ],
  },
  {
    title: 'Policies',
    details: [
      {
        title: 'Refund policy',
        icon: FileTextOutlined,
        action: 'refund',
      },
      {
        title: 'Cancellation Policy',
        icon: FileTextOutlined,
        action: 'cancel',
      },
      {
        title: 'Privacy Policy',
        icon: FileTextOutlined,
        action: 'privacy',
      },
      {
        title: 'Terms & Conditions',
        icon: FileTextOutlined,
        action: 'terms',
      },
    ],
  },
  {
    title: 'Support',
    details: [
      {
        title: 'Send Feedback',
        icon: FormOutlined,
        action: 'feedback',
      },
      {
        title: 'Need help? Contact Support',
        icon: CustomerServiceOutlined,
        action: 'support',
      },
      // TODO: Add a review us on playstore 
      // {
      //   title: 'Review us on Play store',
      //   icon: 'staro',
      //   action: 'review',
      // },
      {
        title: 'Share with friends',
        icon: ShareAltOutlined,
        action: 'share',
      },
    ],
  },
];
