import { createBrowserRouter } from "react-router-dom";

import { Home } from "../pages/Home/Home";
import { Privacy } from "../pages/policies/Privacy";


import { Test } from "../pages/test";
import { PageLayout } from "../pages/PageLayout/PageLayout";
import { MyAdmin } from "../pages/admin/MyAdmin";
import { AddAdmin } from "../pages/admin/AddAdmin";
import { NewPassword } from "../pages/admin/NewPassword";
import { CreateSuperAdmin } from "../pages/admin/CreateSuperAdmin";



// import { FooterComp } from "../components/Footer";


export const router = createBrowserRouter([

  // {
  //   path: "/privacy",
  //   element: <Privacy />
  // },
  {
    path: "/",
    element: <PageLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },

      // {
      //   path: "/privacy",
      //   element: <Privacy />
      // },


    ]
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/my_admin",
    element: <MyAdmin />,

  },
  {
    path: "/add_admin",
    element: <AddAdmin />,

  },
  {
    path: "/new_password",
    element: <NewPassword />,

  },
  {
    path: "/create_superAdmin",
    element: <CreateSuperAdmin />,

  },

]);