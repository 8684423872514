import React from 'react'
import { BsCartCheckFill } from "react-icons/bs";
import styled from 'styled-components'
import { colors, themeConstants } from '../config/theme'
import { AppText, Flex } from '../styles/universalStyles'
import { device } from '../util/devices';


const OrderPlacedComp = styled(Flex)`
  width: 50%;
  padding: 20px;
  background-color: ${colors.PRIMARY};
  position: fixed;
  bottom: 0;
  left: 15%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: ${themeConstants.BOX_SHADOW1};

  @media ${device.mobileS} { 
    width: 100%;
    left: 0;
    padding: 15px;
  }
`

const IconWrap = styled.div`
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 100%;
`

export const OrderConfirmationComp = () => {
  return (
    <OrderPlacedComp>
      <IconWrap>
        <BsCartCheckFill
          color={colors.PRIMARY}
          size="20" />
      </IconWrap>
      <AppText large color={colors.WHITE}>Your Order Has been placed Successfully!</AppText>
    </OrderPlacedComp>
  )
}
