import styled from 'styled-components'

import { colors } from '../config/theme';
import { currencyFormatter } from '../services/util/formatter';
import { AppText, SpaceBetween } from '../styles/universalStyles';




const CartItemWrap = styled(SpaceBetween)`
  gap: 10px;
  margin-bottom: 0.5em;
  background-color: ${colors.LIGHTER_PRIMARY};
  padding: 15px 10px;
`
const TitleSection = styled.div`
  width: 45%;
`
const ItemTitle = styled.h5`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.3em;
  margin: 0;
`


const ActionBtnWrap = styled(SpaceBetween)`
  font-weight: 500;
  margin: 0;
  width: 15%;
  border: 0.7px solid ${colors.LIGHT_PRIMARY};
  height: 30px;
  justify-content: center;
  /* padding: 8px; */
  /* min-width: 35px; */
`

const ActionBtn = styled.div`
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;
`

const QtyDisplay = styled.p`
  margin: 0;
  padding: 0;
`

const Price = styled.h5`
  font-size: 13px;
  font-weight: 400;
  margin: 0;
`

export const CartItem = ({ item, quantity, updateCart, ...props }) => {

  const price = Number(item.amount) * item.qty

  return (
    <CartItemWrap>
      <TitleSection>
        <ItemTitle>{item.title} </ItemTitle>
        <AppText fontSize="10px">{currencyFormatter(item.amount)}</AppText>
      </TitleSection>

      <ActionBtnWrap >
        <QtyDisplay>{item.qty}</QtyDisplay>
      </ActionBtnWrap>


      <Price>
        {currencyFormatter(price)}
      </Price>

    </CartItemWrap>
  )
}
