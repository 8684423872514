import React from 'react'
import styled from 'styled-components'
import { colors } from '../config/theme'
import { AppText } from '../styles/universalStyles'
import { LoadingComp } from './LoadingComp'
import { LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  position: absolute;
  width: ${props => props.isMobile ? '100vw' : props.width};
  /* width: ${`100vw`}; */
  height: 100vh;
  background-color: ${colors.LIGHT_PRIMARY};
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`



export const FullscreenLoader = ({ width, isMobile, color, large, text, ...props }) => {
  return (
    <Wrapper width={width} isMobile={isMobile}>
      <LoadingComp
        color={color}
        large={large}
        text={text}
      />
    </Wrapper>
  )
}
