import { SET_APP_LOADER, ORDER_STATE, RESET_ITEM_COUNT } from '../config/type';

export const app = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_APP_LOADER:
      return { ...state, loading: payload };

    case RESET_ITEM_COUNT:
      return { ...state, itemQty: payload };

    case ORDER_STATE:
      return {
        ...state,
        orderStatus: payload,
      };
    default:
      return state;
  }
};
