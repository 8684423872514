import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons'
import { Input } from 'antd';


import { CheckoutBtnComp } from './CheckoutBtn'
import { colors, themeConstants } from '../config/theme';
import { AppText, Flex, Space, SpaceBetween } from '../styles/universalStyles';
import { cartItemCountFormatter, currencyFormatter } from '../services/util/formatter';
import { customizations } from '../services/data/customizations';
import { addCustomization, resetCart } from '../services/redux/actions/cart';
import { addOrderLocation } from '../services/redux/actions/location';
import { RadioBtn } from './RadioBtn';
import { AppDrawer } from './Drawers';
import { LocationComp } from './NavbarComp/LocationComp';
import { DisplayAddress } from './DisplayAddress';
import { AppInput } from './AppInput'
import { PaymentComp } from './PaymentComp';
import { generatePID } from '../services/util/paymentIdGen';
import { firebasePostDocWithoutID, getImageUrl } from '../services/util/apiRequests';
import { appLoader, resetItemCount, placeOrder } from '../services/redux/actions/app';
import { FullscreenLoader } from './FullscreenLoader';
import { getMenu } from '../services/redux/actions/menu';
import { displayAlert } from '../util/alerts';
import { paymentProof_RE } from '../services/util/regex';
import { verifyPayment } from '../services/util/payment';


const MainWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10em;
  /* margin-bottom: ${props => props.isMobile ? '10em' : '1em'}; */
`

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: ${props => props.showCheckout ? "block" : "none"};
`

const Footer = styled.div`
  position: fixed;
  width: ${props => props.isMobile ? '100%' : props.width};
  bottom: 0;
  right: 0;
  background-color: ${colors.WHITE};
  box-shadow: ${themeConstants.BOX_SHADOW1};
  z-index: 100000;
`

const FlexedSection = styled(SpaceBetween)`
  background-color: ${props => props.color};
  padding: 15px;
  margin-bottom: 1em;

`

const CheckoutSection = styled.div`
  background-color: ${props => props.color};
  padding: 15px;
  margin-bottom: 1em;
`


const PaymentSection = styled.div`
  flex: 1;
`


const CheckoutCompBase = ({
  cart,
  deliveryLocation,
  user,
  action,
  showPayment,
  backBtnAction,
  setLoader,
  loader,
  closeCheckoutDrawer,
  isMobile,
  closePayment,
  width,
  closeCartDrawer,
  ...props
}) => {
  const [customizationType, setCustomizationType] = useState('');
  const [openLocation, setOpenLocation] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [billingError, setBillingError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [imgUpload, setImgUpload] = useState(null);
  const [imgUploadError, setImgUploadError] = useState(false);
  const [showCheckout, setShowCheckout] = useState(true)


  const cartLength = Object.keys(cart).length - 2;
  const deliveryLocationExist = Object.entries(deliveryLocation).length > 0

  const userExists = Object.keys(user).length > 1;
  // const totalAmount = cart.total + (deliveryLocation.deliveryCharge || 0)

  const deliveryCharge =
    (userExists && user.address.details.locationArea.deliveryCharge) ||
    deliveryLocation.deliveryCharge;

  const address =
    (userExists && user.address.details.location) ||
    deliveryLocation.deliveryAddress;

  const totalAmount = (deliveryCharge || 0) + cart.total;

  const handleGoBackAction = () => {
    setShowCheckout(true)
    closePayment()
  }

  const toggleLocationDrawer = () => {
    setOpenLocation(openLocation => !openLocation);
    // props.action()
  };

  const handleCustomization = (itemName) => {
    setCustomizationType(itemName.title);
    props.addCustomization(itemName.title);
  };

  const validateOrder = () => {
    setBillingError(false)
    setAddressError(false)

    if (!deliveryLocationExist) {
      setAddressError(true)
      displayAlert('error', "Please add your delivery address")
      return
    }

    if (!email || !phoneNumber || !fullName) {
      setBillingError(true)
      displayAlert('error', "Please add billing details")
      return
    }

    action()
    isMobile && setShowCheckout(false)
  }

  const validatePaymentProof = async () => {
    if (!imgUpload) {
      setImgUploadError(true)
      return
    }

    // Validate file Ext
    if (!paymentProof_RE.exec(imgUpload.name)) {
      setImgUploadError(true)
      displayAlert('error', "Please upload Image/Pdf of your payment proof")
      return
    }

    setLoader(true)
    setImgUploadError(false)

    // upload payment proof here
    const downloadURL = await getImageUrl('orders', imgUpload);
    await handlePlaceOrder({ downloadURL, reference: null })
    displayAlert('success', "Your Order has been placed successfully")
  }

  const onPaymentSuccess = (response) => {
    setLoader(true)
    const transactionData = verifyPayment(response.reference)
    transactionData.then(async (data) => {
      if (data.status) {

        // place order here
        await handlePlaceOrder({ reference: data.data.reference })

        setLoader(false)
      }
    })

  };

  /**
   * handles placing the order
   * @param {Object} paymentProof contains: downloadURL & reference
   */
  const handlePlaceOrder = async (paymentProof) => {
    // set Apploader modal to loading
    setLoader(true)
    props.resetItemCount(true)


    const { downloadURL, reference } = paymentProof;
    // get userData
    let userData = {
      uid: userExists ? user.uid : 'Anonymous',
      email: userExists ? user.email : 'email',
      displayName: userExists ? user.displayName : fullName,
      phoneNumber: userExists ? user.phoneNumber : phoneNumber,
      areaName: userExists ? user.address.details.locationArea.areaName : deliveryLocation.areaName,
      howToReach: userExists ? user.address.details.howToReach : deliveryLocation.howToReach,
      floor: userExists ? user.address.details.floor : deliveryLocation.floor,
    };

    try {
      // generate Payment ID
      const paymentId = reference || await generatePID();

      // Arrange Order
      const orderObj = {
        ...userData,
        deliveryCharge,
        cart,
        deliveryAddress: address,
        paymentProof: downloadURL || '',
        paymentMethod: downloadURL ? 'Offline Payment' : 'Paystack',
        boliType: cart.customization || 'ripe',
        status: 'booked',
        paymentId,
        token: paymentId,
        orderedFrom: 'Website',
        orderTotal: totalAmount
      };

      // submit order
      await firebasePostDocWithoutID('orders', orderObj)
      props.placeOrder('Your Order has been Placed Successfully');

      // set loader to false
      setLoader(false)

      // Reset state
      setImgUpload(null)
      backBtnAction()
      closeCheckoutDrawer()
      setShowCheckout(true)
      props.resetItemCount(false)
      closeCartDrawer && closeCartDrawer();

      // reset cart
      props.resetCart();

    } catch (error) {
      displayAlert('error', "An error occured while placing your order")
    }

  }

  return (
    <>
      <MainWrapper isMobile={isMobile}>


        <Wrapper showCheckout={showCheckout} isMobile={isMobile} showPayment={showPayment}>

          {/* CartItems total */}
          <FlexedSection color={colors.LIGHT_PINK}>
            <AppText fontSize="20px">{cartItemCountFormatter(cartLength)}</AppText>
            <AppText fontSize="20px" semiBold >{currencyFormatter(cart.total)}</AppText>
          </FlexedSection>
          <FlexedSection color={colors.LIGHT_PINK}>
            <AppText fontSize="14px">Delivery Charge</AppText>
            <AppText fontSize="14px" semiBold >{deliveryLocationExist ? currencyFormatter(deliveryLocation.deliveryCharge) : "NA"}</AppText>
          </FlexedSection>

          {/* customization section */}
          <CheckoutSection color={colors.LIGHTER_PRIMARY}>
            <AppText fontSize="18px">Customize your boli (Choose one)</AppText>
            <Space />
            <RadioBtn
              action={handleCustomization}
              data={customizations}
              initial
              capitalize
            />
          </CheckoutSection>

          {/* Location section */}

          {deliveryLocationExist ?
            <DisplayAddress
              action={toggleLocationDrawer}
              data={deliveryLocation}
            />
            :
            (
              <CheckoutSection
                color={addressError ? colors.DANGER_BACKGROUND : colors.LIGHTER_PRIMARY}
                style={{ cursor: "pointer" }}
                onClick={toggleLocationDrawer}
              >
                <Flex >
                  <EnvironmentOutlined style={{ fontSize: "20px", color: colors.DANGER }} />
                  <Space size="3px" />
                  <AppText fontSize="18px" color={colors.DANGER}>Click to Add Delivery Address</AppText>
                </Flex>
                <AppText fontSize="14px">Please add your address to help get your mouth watering order faster</AppText>
                <Space size="3px" />
                <AppText small color={colors.LIGHT_GREY} bold>Delivery Charge: Not Available</AppText>
                {
                  addressError && (
                    <>
                      <Space />
                      <AppText small color={colors.DANGER}>Please add your delivery address </AppText>
                    </>
                  )
                }

              </CheckoutSection>
            )
          }

          <CheckoutSection
            color={billingError ? colors.DANGER_BACKGROUND : colors.LIGHTER_PRIMARY}
          // color={colors.LIGHTER_PRIMARY}
          >
            {
              billingError && (
                <>
                  <AppText fontSize="13px" color={colors.DANGER}>Please fill in all the details for billing</AppText>
                  <Space />
                </>
              )
            }
            <AppText fontSize="18px" bold>Billing Details</AppText>
            <Space />

            <div>
              <AppText fontSize="13px" color={colors.LIGHT_GREY}>Full Name</AppText>
            </div>
            <Space size="5px" />
            <AppInput
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              placeholder="Enter your full name"
              bordered={false}
            />

            <Space />
            <div>
              <AppText fontSize="13px" color={colors.LIGHT_GREY}>Email Address</AppText>
            </div>
            <Space size="5px" />
            <AppInput
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter your email"
              bordered={false}
            />
            <Space />
            <div>
              <AppText fontSize="13px" color={colors.LIGHT_GREY}>Phone Number</AppText>
            </div>
            <Space size="5px" />
            <AppInput
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              placeholder="Enter your phone number"
              bordered={false}
            />
          </CheckoutSection>

          {/* Location Drawer */}

          <AppDrawer
            toggleDrawer={toggleLocationDrawer}
            open={openLocation}
            placement="right"
            width={'500px'}
            isMobile={isMobile}
          >
            <LocationComp
              isMobile={isMobile}
              closeLocationDrawer={() => setOpenLocation(openLocation => !openLocation)}
              placement="right" />
          </AppDrawer>

          <Footer width={width} isMobile={isMobile}>
            <CheckoutBtnComp
              action={showPayment ? validatePaymentProof : validateOrder}
              btnText={showPayment ? "Place Order" : "Make Payment"}
              title="Total"
              subtitle="No hidden charges"
              total={totalAmount} />
          </Footer>

        </Wrapper>

        {
          showPayment && (
            <PaymentSection>
              <PaymentComp
                userName={fullName}
                phoneNumber={phoneNumber}
                email={email}
                backBtnAction={handleGoBackAction}
                amount={totalAmount}
                imgUploadAction={(file) => setImgUpload(file)}
                imgUploadError={imgUploadError}
                onPaymentSuccess={onPaymentSuccess}
                loader={loader}
                isMobile={isMobile}
                showChildren={!!imgUpload}
              >
                {isMobile && (
                  <Footer width={width} isMobile={isMobile}>
                    <CheckoutBtnComp
                      action={showPayment ? validatePaymentProof : validateOrder}
                      btnText={showPayment ? "Place Order" : "Make Payment"}
                      title="Total"
                      subtitle="No hidden charges"
                      total={totalAmount} />
                  </Footer>
                )}
                {loader && (
                  <FullscreenLoader
                    width={width}
                    isMobile={isMobile}
                    color={colors.WHITE}
                    large
                    text="Placing your Order..."
                  />)}

              </PaymentComp>
            </PaymentSection>
          )
        }

      </MainWrapper>
    </>
  )
}


const mapStateToProps = (state) => {

  return {
    cart: state.cart,
    user: state.user,
    extraItems: state.cart.extra,
    deliveryLocation: state.location.orderLocation || {},
    loader: state.app.loading || false,
    menu: state.menu
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomization: (item) => dispatch(addCustomization(item)),
    addOrderLocation: (data) => dispatch(addOrderLocation(data)),
    setLoader: (status) => dispatch(appLoader(status)),
    resetItemCount: (status) => dispatch(resetItemCount(status)),
    resetCart: () => dispatch(resetCart()),
    placeOrder: (status) => dispatch(placeOrder(status)),
    getMenu: () => dispatch(getMenu()),


  };
};

export const CheckoutComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutCompBase);
