import { Alert } from 'antd';


export const screenActions = (actionText, navigation, userExists) => {
  switch (actionText) {
    case 'history':
      if (!userExists) {
        return alert(
          "Please login to view your order history",
        );
      }
      navigation.navigate('OrderHistory');
      return;
    case 'favourite':
      alert(actionText);
      break;
    case 'refund':
      navigation.navigate('RefundScreen')
      break;
    case 'cancel':
      navigation.navigate('CancellationScreen')
      break;
    case 'privacy':
      navigation.navigate('PrivacyScreen')
      break;
    case 'terms':
      navigation.navigate('TermsScreen')
      break;
    case 'feedback':
      Alert.alert(
        'Send Feedback',
        'Please send your feedback through WhatsApp or email and we will reply as soon as possible. Thank you :)\n\nPhone: +234 809 577 8857\nEmail: lucianasgrill@gmail.com',
        [{ text: 'OK' }],
        { cancelable: false },
      );
      return;
    case 'support':
      Alert.alert(
        'Contact Suport',
        'Please message us on WhatsApp and we will reply as soon as possible.\n\nPhone: +234 809 577 8857\nEmail: lucianasgrill@gmail.com',
        [{ text: 'OK' }],
        { cancelable: false },
      );
      return;

    case 'review':
      alert(actionText);
      break;
    case 'share':
      // onShare();
      return;

    default:
      break;
  }
};


// const onShare = async () => {
//   try {
//     const result = await Share.share({
//       message:
//         'I have been having great boli from this plug and I am happy to recommend her to you. \n\nWhatsApp : https://wa.me/c/2348095778857\nTwitter: https://twitter.com/lucianas_grill\nWebsite: https://www.lucianasgrill.com',
//       // message:
//       //   'I have been having great boli from this plug and I am happy to recommend them to you. \n\nhttps://twitter.com/lucianas_grill',
//       title: "Luciana's Grill App",
//       url: 'https:google.com',
//     });
//     if (result.action === Share.sharedAction) {
//       if (result.activityType) {
//         console.log(result);
//         // shared with activity type of result.activityType
//       } else {
//         // shared
//         console.log(result);
//       }
//     } else if (result.action === Share.dismissedAction) {
//       // dismissed
//       console.log(result);
//     }
//   } catch (error) {
//     alert('An error occured while sharing the app', error.message);
//   }
// };
