import {SAVE_USER, SIGNOUT_USER} from '../config/type';

export const user = (state = {}, action) => {
  const {type, payload} = action;
  switch (type) {
    case SAVE_USER:
      return {...payload};
    case SIGNOUT_USER:
      return {};

    default:
      return state;
  }
};
