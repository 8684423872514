
export const TEXT_CONSTANTS = {
  DEFAULT_LOADING: "Loading ...",
  MENU_LOADING: "Kukurukuu cook that thing ...",
  PROFILE_PAGE: {
    TITLE: 'Login or Signup',
    SUB_TITLE: "Login or Signup to have access to our mouth-watering offers and delicacies",
  },
  CART: {
    EMPTY_DESC: "Go ahead, add something yummy, you deserve it!",
  },
  LOGOUT: "Logout"
}