import {GET_ORDERS} from '../config/type';

export const orders = (state = [], {payload, type}) => {
  switch (type) {
    case GET_ORDERS:
      return [...payload];

    default:
      return state;
  }
};
