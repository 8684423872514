import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";


export const firebaseConfig = {
  apiKey: "AIzaSyBIT0TK2_ctOUifygmE10m6GxT_Vkm1L5A",
  authDomain: "luciana-grill.firebaseapp.com",
  databaseURL: "https://luciana-grill.firebaseio.com",
  projectId: "luciana-grill",
  storageBucket: "luciana-grill.appspot.com",
  messagingSenderId: "451328291755",
  appId: "1:451328291755:web:5d85a04cd8b9301ce258ee",
  measurementId: "G-ZZ902Q3R1T"
};


const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);

export const database = getFirestore(app);
export const analytics = getAnalytics(app);
export const authProvider = new GoogleAuthProvider();
export const storage = getStorage(app);
// auth.languageCode = 'it';