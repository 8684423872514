import {combineReducers} from 'redux';
import {cart} from './cart';
import {app} from './app';
import {menu} from './menu';
import {user} from './user';
import {orders} from './orders';
import {location} from './location';

const initialState = {};
export default combineReducers({
  initialState,
  cart,
  app,
  menu,
  user,
  orders,
  location,
});
