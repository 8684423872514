import 'intl';
import 'intl/locale-data/jsonp/en';
import { generatePID } from './paymentIdGen';

export const currencyFormatter = (amount) => {
  const formattedAmount = new Intl.NumberFormat().format(amount);
  return `₦ ${formattedAmount}`
};

export const capitalizeFormatter = (str) => {
  if (!str) return;
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const getUserAcronym = (str) => {
  if (str) {
    let format = '';
    format = str.split(' ').map((i) => i.charAt(0));
    return format.join(' ');
  }
};

export const getUserFirstname = (str) => {
  if (str) {
    let format = '';
    format = str.split(' ');
    return format[0];
  }
};

export const cartItemCountFormatter = (count) => {
  if (count > 1) {
    return `${count} ITEMS`
  }
  else {
    return `${count} ITEM`
  }
}

export const truncateStr = (str, charLimit) => {
  const limit = charLimit || 40;

  return (str.length > limit) ? str.slice(0, limit - 1) + '...' : str;
}

/**
 * 
 * @param {String} filename The name of the file
 * @returns 
 */
export const getFileExtension = (filename) => {
  const extArray = filename.split('.')
  const extNumber = extArray.length - 1;
  const fileExt = extArray[extNumber];
  return fileExt
}

export const createFileName = ({ uid, name }) => {
  const fileExt = getFileExtension(name)

  const fileName = uid ? `${uid}.${fileExt}` : `${generatePID()}.${fileExt}`
  return fileName;

}

