import React from 'react'
import styled from 'styled-components'
import { Space } from '../styles/universalStyles'
import { colors } from '../config/theme'

const Label = styled.label`
  font-size: 18px;
  margin-right: 20px;
`

const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  width: 100%;
  border: 1px solid ${colors.LIGHTER_PRIMARY};
  background-color: ${colors.WHITE};
  border-radius: 5px;
  outline: none !important;

  &:focus{
    border: 1px solid ${colors.PRIMARY};
  }
`

const FormGroup = styled.div`
  margin: auto;
  margin-bottom: 20px;
  /* width: 50%; */
`

export const FormGroupComp = ({ labelName, placeholder, inputType, ...props }) => {
  return (
    <FormGroup>
      <Label>{labelName}</Label>
      <Space />
      <Input
        placeholder={placeholder}
        type={inputType}
        name={inputType || props.name}
        required={props.required}
        id={props.id}
      />
    </FormGroup>
  )
}
