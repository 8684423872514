import { PLACE_API } from '../../util/endPoints';
import { LOCATION_SEARCH_ERROR, SAVE_ORDER_LOCATION } from '../config/type';

// Figure out what this is supposed to do
export const searchLocation = (term) => async (dispatch) => {
  try {
    const response = await fetch(`${PLACE_API}${term}`);
    // dispatch({
    //   type: LOCATION_SEARCH,
    //   payload: response.data.predictions,
    // });
  } catch (error) {
    dispatch({
      type: LOCATION_SEARCH_ERROR,
      payload: {
        message: 'Something went wrong, please try again',
        error,
      },
    });
    // console.log('an error occured, ', error);
  }
};

export const addOrderLocation = (locationData) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_ORDER_LOCATION,
      payload: locationData,
    });
  } catch (error) {
    // dispatch({
    //   type: LOCATION_SEARCH_ERROR,
    //   payload: {
    //     message: 'Something went wrong, please try again',
    //     error,
    //   },
    // });
    // console.log('an error occured while adding order location', error);
  }
};
