import {SAVE_ORDER_LOCATION} from '../config/type';

export const location = (state = {}, action) => {
  const {type, payload} = action;
  switch (type) {
    case SAVE_ORDER_LOCATION:
      return {...state, orderLocation: payload};

    default:
      return state;
  }
};
