import { useEffect, useState } from 'react';

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  // useEffect(() => {
  //   mediaMatch.onchange = (e) => setMatches(e.matches)
  // });
  // console.log("matches=======>", { matches, query, width: window.innerWidth })
  return matches;
};
