export const customizations = [
  {
    title: 'ripe',
    index: 1,
  },
  {
    title: 'very ripe',
    index: 2,
  },
  {
    title: 'unripe',
    index: 3,
  },
];
