export const defaultLocation = 'island 3';
export const servingLocation = [
  {
    title: 'island 1',
    streetNames: [
      'Falomo',
      'parkview estate',
      'apogbon',
      'banana island',
      'Broad street',
      'cms',
      'dolphin estate',
      'idumota',
      'ikoyi',
      'lagos island',
      'marina',
      'obalende',
      'victoria island',
    ],
    amount: 2500,
  },
  {
    title: 'island 2',
    streetNames: [
      'sandfill',
      'Elegushi',
      'maruwa',
      'Oniru',
      'lekki phase 1',
      'second rounabout',
      'third roundabout',
      'zion court',
    ],
    amount: 1500,
  },
  {
    title: 'island 3',
    streetNames: [
      'chevron',
      'chisco',
      'ikate',
      'ikota',
      'jakande',
      'alpha beach',
      'abraham adesanya',
      'agungi',
      'ajah',
      'ocean bay estate',
      'osapa london',
      'lagos business school',
      'lekki country homes',
      'thomas estate',
    ],
    amount: 2800,
  },
  {
    title: 'island 4',
    streetNames: [
      'abijo',
      'awoyaya',
      'badore',
      'crown estate',
      'eputu town',
      'ogambo',
      'ibeju-Lekki',
      'Langbasa',
      'sangotedo',
    ],
    amount: 1000,
  },
  {
    title: 'mainland 1',
    streetNames: [
      'yaba',
      'bariga',
      'aloka',
      'constain',
      'ebute-Metta',
      'fadeyi',
      'idi araba',
      'iganmu',
      'ipanri',
      'itire',
      'iwaya',
      'iyana owora',
      'jibowu',
      'orile',
      'ajegunle',
      'mushin',
      'obanikoro',
      'onipanu',
      'ojuelegba',
      'oworonshaki',
      'palmgrove',
      'shomalu',
      'surulere',
      'alaba suru',
    ],
    amount: 2500,
  },
  {
    title: 'mainland 2',
    streetNames: [
      'anthony',
      'ikeja',
      'isolo',
      'maryland',
      'mende',
      'illupeju',
      'MMA1',
      'MM2',
      'oshodi',
      'shogunle',
      'alausa',
      'ajao estate',
    ],
    amount: 2800,
  },
  {
    title: 'mainland 3',
    streetNames: [
      'ketu',
      'mmagoda phase 1 & 2',
      'mile 12',
      'ogudu berger',
      'omola phase 1 & 2',
      'owode',
      'unilag estate',
      '7up',
      'olowora',
    ],
    amount: 3500,
  },
  {
    title: 'mainland 4',
    streetNames: [
      'agege',
      'fagba',
      'ogba',
      'iju ishaga',
      'akowonja',
      'ayaba',
      'egbeda',
      'idumota',
      'ejigbo',
      'iyana ipaja',
      'idimu',
      'iganda',
      'ikotun',
      'egbe',
      'meiran',
      'abule egba',
    ],
    amount: 4000,
  },
  {
    title: 'mainland 5',
    streetNames: [
      'apapa',
      'abule osun',
      'alaba',
      'alakija',
      'amufo odofin',
      'festac',
      'kirikiri',
      'LASU',
      'mazaMaza',
      'navy town',
      'okokomaiko',
      'satellite town',
      'volks',
    ],
    amount: 5000,
  },
];
