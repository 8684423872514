import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  getDoc,
  doc,
  addDoc,
  serverTimestamp
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { database, storage } from "../../config/firebase";
import { createFileName } from "./formatter";



/**
 * Fetches Collection Data
 * @param {String} collectionName 
 * @returns collectionData
 */
export const firebaseGetCollection = async (collectionName) => {
  try {

    const dbCollection = collection(database, collectionName);
    const collectionDoc = await getDocs(dbCollection);
    const CollectionData = collectionDoc.docs.map(doc => doc.data());
    return CollectionData;


  } catch (error) {
    // console.log(`Error while fetching ${collectionName} =======>:`, error)
  }
}

/**
 * Fetches single doc from a collection
 * @param {String} collectionName is the name of the collection beng queried
 * @param {String} docId is the ID of the queried doc
 * @returns document or null
 */
export const firebaseGetSingleDoc = async (collectionName, docId) => {
  try {
    const docRef = doc(database, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null
    }
  } catch (error) {
    // console.log('Error occured while fetching from firestore', { error });
  }
}


/**
 * Posts to collection specifying the docID
 * @param {String} collectionName The name of collection
 * @param {String} docId ID of the document
 * @param {Object} data The data to save in the collection
 */
export const firebasePostDoc = async (collectionName, docId, data) => {
  try {
    await setDoc(doc(database, collectionName, docId), {
      ...data,
      timestamp: serverTimestamp()
    });
  } catch (error) {
    // console.log(`An error occured while adding document to firebase collection: ${collectionName}`, { error })
  }
}

/**
 * Posts to collection specifying the docID
 * @param {String} collectionName The name of collection
 * @param {String} docId ID of the document
 * @param {Object} data The data to save in the collection
 */
export const firebasePostDocWithoutID = async (collectionName, data) => {

  try {
    await addDoc(collection(database, collectionName), {
      ...data,
      timestamp: serverTimestamp()
    });

  } catch (error) {
    // console.log(`An error occured while adding document to firebase collection: ${collectionName}`, { error })
  }
}


/**
 * Updates a firebase document
 * @param {String} collectionName The name of the collection    
 * @param {Object} data the data to be updated
 * @param {String} docId the ID of the doc to be updated
 * @returns true if update operation is complete
 */
export const firebaseUpdateDoc = async (collectionName, data, docId) => {
  // const { uid } = getCurrentUser();
  try {
    const docRef = doc(database, collectionName, docId);
    await updateDoc(docRef, { ...data });

    return true

  } catch (error) {
    // console.log(`An error occured while updating the user ${collectionName}`, { error });
  }
};


/**
 * getImageURL
 * @param {Dir Name} folderName Name of the folder to save the file
 * @param {File} file the file oblect
 * 
 * @returns the downloadURL for the file
 */
export const getImageUrl = async (folderName, file) => {
  try {

    const fileName = createFileName({ uid: file.uid, name: file.name });

    // set bucket reference
    const reference = ref(storage, `/${folderName}/${fileName}`);

    // upload file
    await uploadBytes(reference, file)

    // get download url
    const downloadURL = await getDownloadURL(reference);

    return downloadURL;
  } catch (error) {
    // console.log('An error occured while uploading image', { error });
  }
};

