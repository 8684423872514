import { LoadingOutlined } from "@ant-design/icons";

import { colors } from "../config/theme"
import { AppText, Space } from "../styles/universalStyles"
import { AppButton } from "./AppButtons"
import { AppInput } from "./AppInput"


export const DisplayEmailLogin = ({
  email,
  setEmail,
  setPassword,
  password,
  emailError,
  handleEmailSignIn,
  mailLoading
}) => {
  return (
    <>
      <div>
        <AppText fontSize="13px" color={colors.LIGHT_GREY}>Your Email</AppText>
      </div>
      <Space size="5px" />
      <AppInput
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder="Enter your email"
        required
        bordered={false}
      />

      <Space />
      <div>
        <AppText fontSize="13px" color={colors.LIGHT_GREY}>Your Password</AppText>
      </div>
      <Space size="5px" />
      <AppInput
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        required
        placeholder="Enter password"
        bordered={false}
      />
      <Space size="5px" />

      {
        emailError && <AppText fontSize="13px" color={colors.DANGER}>Please fill correct details above</AppText>
      }
      <AppButton
        // disabled={mailLoading}
        action={handleEmailSignIn}
        showIcon={mailLoading}
        icon={<LoadingOutlined style={{ color: colors.WHITE, fontSize: "15px", fontWeight: 600 }} />}
        // text={phoneLoading ? "Sending OTP" : "Get OTP"}
        btnText={mailLoading ? "Signing up ..." : "Signup"}
      // btnText={"Get OTP"}
      />
    </>
  )
}