import { ORDER_STATE, RESET_CART, SET_APP_LOADER, RESET_ITEM_COUNT } from '../config/type';
import { resetCart } from './cart';

export const appLoader = (loadingState) => (dispatch) => {
  try {
    dispatch({
      type: SET_APP_LOADER,
      payload: loadingState,
    });
  } catch (error) {
    // console.log("something went wrong while setting the app's loader", error);
  }
};


export const resetItemCount = (loadingState) => (dispatch) => {
  try {
    dispatch({
      type: RESET_ITEM_COUNT,
      payload: loadingState,
    });
  } catch (error) {
    // console.log("something went wrong while setting the menu loader", error);
  }
};

/**
 *
 * @param {String} status of the order
 */
export const placeOrder = (status) => (dispatch) => {
  try {
    dispatch({
      type: ORDER_STATE,
      payload: status,
    });

    // dispatch(resetCart());
  } catch (error) {
    // console.log("something went wrong while setting the app's loader", error);
  }
};
