/**
 * 
 * @param {String} searchInput the place to search
 * @param {Function} callback the function that handles the response
 * @returns 
 */
export async function initSearchService(searchInput, callback) {

  const service = new window.google.maps.places.AutocompleteService();

  return service.getQueryPredictions({ input: searchInput }, callback);
}

window.initService = initSearchService;
