export const colors = {
  WHITE: '#fff',
  BLACK: '#000',
  PRIMARY: '#f06d1f',
  GOOGLE: '#DB4437',
  FACEBOOK: '#4267B2',
  TWITTER: '#1DA1F2',
  LIGHT_PINK: 'rgba(255, 192, 203, 0.1)',
  LIGHT_PRIMARY: 'rgba(240,109,31,0.6)',
  LIGHTER_PRIMARY: 'rgba(240,109,31,0.1)',
  LIGHT_GREEN: 'rgba(50, 205, 50, 0.2)',
  LIGHT_GREY: 'rgba(0,0,0,0.6)',
  LIGHTER_GREY: 'rgba(0, 0, 0, 0.2)',
  LIGHTEST_GREY: 'rgba(0, 0, 0, 0.1)',
  TEAL: 'teal',
  DANGER: 'rgb(255,0,0)',
  DANGER_BACKGROUND: 'rgba(255,0,0,0.2)'
};

export const themeConstants = {
  BOX_SHADOW1: '0px 0px 1px 1px rgba(0,0,0,0.2)'
}
