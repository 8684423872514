import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react'
import Icon, { EnvironmentFilled, AimOutlined } from '@ant-design/icons'


import { AppText, } from '../styles/universalStyles'
import { MAP_KEY } from '../services/data/api'
import { colors, themeConstants } from '../config/theme';
import { geocodeCordinates } from '../services/util/locationUtil'

const CompWrapper = styled.div`
  width: 100%;
  height: ${props => props.isMobile ? "400px" : "450px"};
  position: relative;
`

const MarkerWrap = styled.div`
  position: absolute;
  top: 0%;
  margin-top: 50%;
  margin-left: 50%;
`

const LocatorPinWrap = styled.div`
  position: absolute;
  top: 0%;
  right: 10px;
  margin-top: 50%;
  background-color: ${colors.WHITE};
  padding: 8px;
  box-shadow: ${themeConstants.BOX_SHADOW1};
`
const OuterDiv = styled.div`
  border: 3px solid ${colors.PRIMARY};
  width: 20px;
  height: 20px;
  border-radius: 100%;
  `
const InnerDiv = styled.div`
  background-color: ${colors.PRIMARY};
  width: 15px;
  height: 15px;
  margin: 2px auto;
  border-radius: 100%;
`

// Default center
const defaultCenter = {
  lat: 6.465422,
  lng: 3.406448

}


export const GooglemapComp = ({ position, isMobile, action, getUserLocation, userPosition }) => {

  const handleBoundsChange = async (center) => {
    const address = await geocodeCordinates(center)
    action(address)
  }

  return (
    <CompWrapper isMobile={isMobile}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAP_KEY }}
        defaultCenter={defaultCenter}
        center={position}
        defaultZoom={18}
        onChange={({ center }) => handleBoundsChange(center)}
        disableDefaultUI={true}
        options={{
          fullscreenControl: false,
        }}
      >
      </GoogleMapReact>
      <LocationPin />

    </CompWrapper>
  )
}

const UserLocatorDot = () => {
  return (
    <OuterDiv>
      <InnerDiv />
    </OuterDiv>
  )
}



const UserLocator = ({ action }) => {
  return (
    // <LocatorPinWrap onClick={() => alert("click")}>
    <LocatorPinWrap onClick={action}>
      <Icon component={AimOutlined} style={{ fontSize: "24px" }} />
    </LocatorPinWrap>
  )
}



const LocationPin = () => (
  <MarkerWrap className="pin">
    <Icon component={EnvironmentFilled} style={{ fontSize: "24px" }} />

  </MarkerWrap>
)

