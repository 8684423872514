import React, { useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import styled from 'styled-components';
import { UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Upload, Collapse } from 'antd';
import { connect } from 'react-redux';

import { colors } from '../config/theme';
import { AppText, Flex, Space, SpaceBetween } from '../styles/universalStyles';
import { currencyFormatter } from '../services/util/formatter';
import { bankDetails } from '../services/data/bankDetails';
import { getPaymentConfig } from '../services/util/payment';
import { CLIENT_URL } from '../services/util/endPoints';

import { displayAlert } from '../util/alerts';
import { LoadingComp } from './LoadingComp';


const { Panel } = Collapse;
const { Dragger } = Upload;



const Wrapper = styled.div`
  border-left: ${props => props.isMobile ? 'none' : `1px dashed ${colors.LIGHTER_GREY}`};
  flex: 1;
`
const UploadSection = styled.div``

const UploadWrap = styled.div`
  background-color: ${props => props.color};
  padding: 15px;
  margin-bottom: 1em;
`

const TitleWrap = styled.div`
  text-align: center;
  padding: 3em;
`

const Content = styled.div`
  padding: 15px;
  padding-bottom: 1em;
`

const PayOnline = styled.div`
  cursor: pointer;
  /* margin-bottom: 30px; */
  background-color: ${colors.LIGHTER_PRIMARY};
  padding: 15px;
`

const BackArrowWrap = styled.div`
  background-color: ${colors.LIGHTER_PRIMARY};
  padding: 5px 8px;
  border-radius: 100%;
`
const PaymentProofError = styled.div`
  background-color: ${colors.DANGER_BACKGROUND};
  padding: 10px;
`

export const PaymentCompBase = ({
  backBtnAction,
  amount,
  email,
  user,
  userName,
  phoneNumber,
  orderLocation,
  cart,
  imgUploadAction,
  imgUploadError,
  // handlePlaceOrder,
  onPaymentSuccess,
  isMobile,
  children,
  // loader,
  ...props }) => {

  const [loader, setLoader] = useState(false)
  const config = getPaymentConfig(email, amount)
  console.log("🚀 ~ file: PaymentComp.js:83 ~ config:", config)
  const initializePayment = usePaystackPayment(config);

  // console.log("how far")

  const fileProps = {
    name: 'file',
    multiple: true,
    action: CLIENT_URL,
    onChange: (info) => {
      imgUploadAction(info.fileList[0].originFileObj)
    },
    onDrop(e) {
      imgUploadAction(e.dataTransfer.files[0])
    },
  };

  const onPaymentModalClose = () => {
    setLoader(false)
    displayAlert('warning', 'You closed the payment window')
  }

  const handleOnlinePayment = async () => {
    setLoader(true)
    await initializePayment(onPaymentSuccess, onPaymentModalClose)
  }


  return (
    <Wrapper isMobile={isMobile} disabled={true}>
      {isMobile && (
        <Space size="15px" onClick={backBtnAction}>
          <Flex>
            <BackArrowWrap><ArrowLeftOutlined /></BackArrowWrap>
            <Space size="5px" />
            <AppText fontSize="13px" >Show Checkout</AppText>
          </Flex>
          <Space size="2em" />
        </Space>
      )}

      {imgUploadError && (
        <Space size="15px">
          <PaymentProofError>
            <AppText fontSize="16px" color={colors.DANGER}>Please use either of the below methods for payment</AppText>
          </PaymentProofError>
        </Space>
      )}

      {/* TODO: commented it out till we have a better payment system */}
      {/* <Space size="15px">
        <PayOnline
          onClick={handleOnlinePayment}
        >
          <AppText fontSize="20px">Pay Online</AppText>
        </PayOnline>
      </Space> */}

      <Collapse ghost>
        <Panel
          showArrow={false}
          header={
            <PayOnline>
              <AppText fontSize="20px">Upload Payment Proof</AppText>
            </PayOnline>
          }
          key="1">

          <UploadSection>
            <UploadWrap color={colors.LIGHTER_PRIMARY}>
              <TitleWrap>
                <AppText fontSize="20px">Upload Payment Proof</AppText>
              </TitleWrap>
              <Dragger
                locale={{ uploading: false, uploadError: false }}
                maxCount={1}
                accept="image/x-png,image/jpeg,image/jpg, application/pdf"
                {...fileProps}>
                <p className="ant-upload-drag-icon">

                  <UploadOutlined style={{ color: colors.BLACK }} />
                </p>
                <p className="ant-upload-text"> <AppText fontSize="16px">Click or drag file to this area to upload </AppText><br />
                  <AppText small color={imgUploadError ? colors.DANGER : colors.LIGHT_GREY} >[only Image/Pdf Files are accepted]</AppText>
                </p>

              </Dragger>

            </UploadWrap>

            <Space />
            <Content>
              <AppText>
                Please pay{' '}
                <AppText bold>{`${currencyFormatter(amount)}`}</AppText>{' '}
                to the account details below and upload the payment proof to
                place your order
              </AppText>

              <Space />
              <AppText bold>Bank Details</AppText>

              <Space size="2px" />

              {bankDetails.map((bankDetail, index) => (
                <div key={index}>
                  <SpaceBetween>
                    <AppText bold>
                      {`${bankDetail.name}:`}{' '}
                      <AppText fontSize="16px" style={{ letterSpacing: '1px' }} >{`${bankDetail.value}`}</AppText>
                    </AppText>
                  </SpaceBetween>
                  <Space size="5px" />
                </div>
              ))}
              {/* {isMobile && (<Space size="10em" />)} */}
            </Content>

          </UploadSection>
        </Panel>
      </Collapse>

      {children}

      {
        loader && (
          <div>
            <LoadingComp fontSize="18px" text="Placing your order, this might take a minute" />
          </div>
        )
      }

    </Wrapper>
  );
};


const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    order: state.order,
    user: state.user,
    loader: state.app.loading || false,
    orderLocation: state.location.orderLocation,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     placeOrder: (status) => dispatch(placeOrder(status)),
//     resetCart: () => dispatch(resetCart()),
//     setLoader: (status) => dispatch(appLoader(status)),
//   };
// };

export const PaymentComp = connect(
  mapStateToProps,
  null,
)(PaymentCompBase);