import React from 'react'
import styled from 'styled-components'
import { AiOutlineCaretRight } from "react-icons/ai";

import { colors } from '../config/theme'
import { Flex, Space, SpaceBetween } from '../styles/universalStyles'

const Wrapper = styled.div`
  background-color: ${colors.LIGHTER_PRIMARY};
  padding: 15px 10px;
  margin-top: 2em;
  border-top: 1px solid ${colors.PRIMARY};
`

const BtnTitle = styled.h5`
  padding: 0;
  margin: 0;
  font-weight: 500;
`
const BtnSubTitle = styled.h6`
  padding: 0;
  margin: 0;
  margin-top: 2px;
  font-weight: 300;
  font-size: 13px;
  color: ${colors.LIGHT_GREY};
`

const BtnWrap = styled.button`
  display: flex;
  background-color: ${colors.PRIMARY};
  padding: 18px 10px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-weight: 500;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: ${colors.WHITE};
  font-weight: 700;
  width: 100%;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : "pointer"};
  opacity: ${props => props.disabled ? 0.5 : 1};
`


export const AppButtonWithIcon = (props) => {
  return (
    <Wrapper>
      <SpaceBetween>
        <BtnTitle>Subtotal</BtnTitle>
        <BtnTitle>N2300</BtnTitle>
      </SpaceBetween>
      <BtnSubTitle>Extra charges may apply</BtnSubTitle>

      <Space size="2em" />

      <BtnWrap onClick={props.action}>
        Btn
        <AiOutlineCaretRight />
      </BtnWrap>
    </Wrapper>
  )
}

export const AppButton = ({ btnText, action, disabled, children, ...props }) => {

  return (
    <Wrapper>
      <BtnWrap
        disabled={disabled}
        {...props}
        onClick={action}
      >
        {btnText || children}
      </BtnWrap>
    </Wrapper>
  )
}


// export const IconBtn = ({ text, icon, showIcon }) => {
//   return (
//     <Flex style={{ padding: 0, backgroundColor: "pink" }}>

//       {showIcon &&
//         (
//           <>
//             {icon}
//             <Space />
//           </>
//         )

//       }

//       <AppParagraph fontSize="15px" color={colors.WHITE} bold>{text}</AppParagraph>
//     </Flex>
//   )
// }

