import { message } from 'antd';

/**
 * Displays an alert
 * @param {String} type either "success", "warning" or "Error"
 * @param {String} content "the message content"
 */
export const displayAlert = (type, content) => {
  message.open({
    type,
    content,
    style: {
      marginTop: '20vh',
    },
  });
};